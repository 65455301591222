import 'regenerator-runtime/runtime.js';
import React, { useState } from 'react';
import { Button, IconButton, Menu, MenuItem,Paper, AppBar, Toolbar, Typography} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material';

export function TopBar(props) {
   return (
      <Paper>
         <SimpleMenu id={'topBarUserDropdown'}
            pageTitle={props.pageTitle}
            user={props.user}
            drawerOpen={props.drawerOpen}
            toggleMenu={props.toggleMenu}
            >
         </SimpleMenu>
      </Paper>
   )
}

function SimpleMenu(props) {
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const logout = () => {
      //clear local storage
      window.localStorage.removeItem('idUser');
      window.localStorage.removeItem('user');
      window.localStorage.removeItem('isAdmin');
      window.localStorage.removeItem('scripts');
      window.localStorage.removeItem('kiosks');
      window.localStorage.removeItem('idOwner');

      window.location = '/user/logout';
   }
   const toggleMenu = () => {
      if(props.toggleMenu)
         props.toggleMenu()
   }

   let firstName = props.user.firstName || '';
   let lastName = props.user.lastName || '';

   return (
         <AppBar position='fixed'
            open={props.drawerOpen}
         >
            <Toolbar color='inherit'>
               <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleMenu}
                  edge="start"
                  sx={{ mr: 2, ...(props.drawerOpen && { display: 'none' }) }}
               >
                  <MenuIcon/>
               </IconButton>
               <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {props.pageTitle}
               </Typography>
               <Button
                  color='inherit'
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick} >
                  Hello {firstName}
               </Button>
            </Toolbar>
            <Menu
               id="simple-menu"
               anchorEl={anchorEl}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
               }}
               keepMounted
               open={Boolean(anchorEl)}
               onClose={handleClose}
            >
               <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
         </AppBar>

   );
}
