//from https://loading.io/css/
export function CreateLoadingOverlay(){
   let loadingScreenStyle = `
    .bv-lds-ring-overlay {
       position: absolute;
       width: 100%;
       height: 100%;
       top: 0;
       left: 0;
       z-index: 2;
       background: rgba(0,0,0,0.6);
       display: flex;
       justify-content: center;
       align-items: center;
   }
   .bv-lds-ring-overlay .rot-part {
     box-sizing: border-box;
     display: block;
     position: absolute;
     width: 64px;
     height: 64px;
     margin: 8px;
     border: 8px solid #fff;
     border-radius: 50%;
     animation: bv-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
     border-color: #fff transparent transparent transparent;
   }
   .bv-lds-ring-overlay div:nth-child(1) {
     animation-delay: -0.45s;
   }
   .bv-lds-ring-overlay div:nth-child(2) {
     animation-delay: -0.3s;
   }
   .bv-lds-ring-overlay div:nth-child(3) {
     animation-delay: -0.15s;
   }
   
   .bv-lds-ring-overlay .loading-overlay-text {
     color: white;
     position: relative;
     top: 65px;
   }
   
   @keyframes bv-lds-ring-overlay {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(360deg);
     }
   }
`;

   let styleEl = document.createElement('style');
   styleEl.innerText = loadingScreenStyle;
   document.head.appendChild(styleEl);

   let firstDiv = document.createElement('div');
   firstDiv.classList.add('bv-lds-ring-overlay');
   for(let i = 0; i < 4; i++){
      let rotPart = document.createElement('div');
      rotPart.classList.add('rot-part');
      firstDiv.appendChild(rotPart);
   }
   let loadingText = document.createElement('div');
   loadingText.classList.add('loading-overlay-text');
   loadingText.innerText = 'Loading';
   firstDiv.appendChild(loadingText);

   return firstDiv;

}