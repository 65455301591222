import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/styles';
import { Button, Collapse, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {ChevronLeft} from '@mui/icons-material';
import {KeyboardArrowUp as KeyboardArrowUpIcon} from '@mui/icons-material';
import {KeyboardArrowDown as KeyboardArrowDownIcon} from '@mui/icons-material';

const DrawerHeader = styled('div')(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'flex-end',
 }));

function MenuItem(props){

   const expand = (e) => {
      e.stopPropagation();
      props.expandFunction()
   }
   return(
      <ListItem sx={props.sx} button={true} onClick={props.clickFunction} style={{minWidth:'200px'}} selected={props.selected}>
         <ListItemText align={'left'} primary={props.text} />
         {
            props.expandFunction && 
            <IconButton onClick={(e)=> expand(e)}>
               {props.open? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
            </IconButton>
         }
      </ListItem>
   )
}
export function PersistentDrawer(props) {

   const [templateOpen, setTemplateOpen] = useState(false);
   const [recentOpen, setRecentOpen] = useState(false);
   const [scripts, setScripts ] = useState([]);
   const [kiosks, setKiosks] = useState([])
   useEffect(() => {
      let scripts = JSON.parse(window.localStorage.getItem('scripts'));
      let kiosks = JSON.parse(window.localStorage.getItem('kiosks'));
      setKiosks(kiosks);
      setScripts(scripts);
   }, [])

   const toggleMenu = () => {
      if(props.toggleMenu);
      props.toggleMenu();
   }


   const goToPage = (page) => {
      if(window.localStorage.getItem('isAdmin'))
         window.location.href = `/organization/${page}?idUser=${window.localStorage.getItem('idUser')}`;
      else
         window.location.href = `/organization/${page}`;
   }

   const gotoAdmin = () => {
      window.localStorage.removeItem('idUser');
      window.localStorage.removeItem('user');
      window.localStorage.removeItem('isAdmin');
      window.localStorage.removeItem('scripts');
      window.localStorage.removeItem('kiosks');
      window.localStorage.removeItem('idOwner');
      window.location.href = `/admin`;
   }
   function MenuList() {
      return (
         <div>
            {
               window.localStorage.getItem('isAdmin') &&
               <MenuItem text='Back to Admin' clickFunction={() => gotoAdmin()} selected={ props.pagePath ==="admin"}/>
            }
            <MenuItem text='Analytics' clickFunction={() => goToPage('dashboard') } selected={ props.pagePath ==="dashboard"}/>

            {
               window.localStorage.getItem('idOwner') == '26' &&
               <div>
                  <MenuItem text='Recent Photos' clickFunction={() => goToPage('recent') } selected={ props.pagePath ==="recent"}
                  expandFunction={() => setRecentOpen(!recentOpen)} open={recentOpen}/>

                  <Collapse in={recentOpen} timeout="auto" unmountOnExit>
                     <List component="div" disablePadding>
                        <MenuItem
                           sx={{ pl: 4 }}
                           key={`sidebar-web`}
                           text={'Web'}
                           clickFunction={()=> goToPage(`recent/web`)}
                        />
                        {/* {
                           kiosks.map(kiosk => {
                              return (
                                 <MenuItem
                                    sx={{ pl: 4 }}
                                    key={`sidebar${kiosk.nickname}`}
                                    text={kiosk.nickname}
                                    clickFunction={()=> goToPage(`recent/kiosk/${kiosk.idKiosk}`)}
                                 />
                              );
                           })
                        } */}
                     </List>
                  </Collapse>
               </div>
            }
            <MenuItem text='Themes' clickFunction={() => goToPage('templates')} selected={ props.pagePath ==="templates"}
               expandFunction={() => setTemplateOpen(!templateOpen)} open={templateOpen}/>

            <Collapse in={templateOpen} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>
                  {
                     scripts.map(script => {
                        return (
                           <MenuItem
                              sx={{ pl: 4 }}
                              key={`sidebar${script.nickname}`}
                              text={script.nickname}
                              clickFunction={()=> goToPage(`templates/edit/${script.idScript}`)}
                           />
                        );
                     })
                  }
               </List>
            </Collapse>
         </div>
      )
   }
   return (
      <Drawer anchor="left" variant="persistent" open={props.drawerOpen}>
         <DrawerHeader>
            <IconButton onClick={toggleMenu}>
               <ChevronLeft/>
            </IconButton>
          </DrawerHeader>
         <List className="sidebar">
            {MenuList()}
         </List>
      </Drawer>
   )
}
