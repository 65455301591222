import 'regenerator-runtime/runtime.js';
import {renderSourceToCanvas, dataURLtoBlob, resolveUrl} from '../utils.js';
import {getBrowserName, isIOS} from '../systeminfo.js';
import * as ebml from '../ts-ebml.min.js';
import {
   createImageForSource, createWebcamForSource, createSvgForSource, createVideoForSource, createCustomTextForSource
} from './sourceElements.js';
import * as html2Img from 'html-to-image';
import {PhotoboothWebcam} from './photoboothWebcam.js';
import {ProgressRing} from '../uiElements/progressRing.js';
import {ColorLevelSet} from './imageProcessorStages/colorLevelSet.js';
import {CreateLoadingOverlay} from "../loadingOverlay.js";

export class PhotoBoothExperience{
   constructor(props) {
      this.videoSources = props.videoSources;
      this.overlayVariables = props.overlayVariables;
      this.width = props.width;
      this.height = props.height;
      this.fixedAspectRatio = props.fixedAspectRatio;
      this.maxWidth = props.maxWidth || Number.MAX_SAFE_INTEGER;
      this.maxHeight = props.maxHeight || Number.MAX_SAFE_INTEGER;

      this.rawWebcamImage = null;

      this.idealCameraWidth = props.idealCameraWidth;
      this.idealCameraHeight = props.idealCameraHeight;
      this.idealCameraFPS = props.idealCameraFPS;
      this.cameraRotation = props.cameraRotation;
      this.videoCaptureMode = props.videoCaptureMode;
      this.flipFinalScreenshotX = props.flipFinalScreenshotX;

      this.imageProcessorMaskBlurAmount = props.imageProcessorMaskBlurAmount;

      this.useOffscreenRenderCanvas = typeof OffscreenCanvas !== 'undefined' && !props.finalMediaIsVideo && !(isIOS() || getBrowserName() === 'Safari');

      this.captureImageWidth = props.captureImageWidth;
      this.captureImageHeight = props.captureImageHeight;


      this.segmentationInProcess = false;

      this.isLoading = false;

      this.loadingOverlay = CreateLoadingOverlay();
      this.loadingOverlay.style.display = 'none';
      document.body.append(this.loadingOverlay);

      this.rootElement = document.getElementById('bvPhotoBoothRoot');
      if(!this.rootElement){
         this.rootElement = document.createElement('bvPhotoBoothRoot');
         document.body.append(this.rootElement);
      }

      this.ignoreMouseEvents = false;

      let dispatchPointerEvent = (eventType, e) => {
         if(window?.BV?.lensIntegration){
            if(!this.lensCanvas){
               this.lensCanvas = document.getElementById('lensCanvas');
               this.lensCanvas.addEventListener('click', eLens => {
                  console.debug(eLens);
               });
               this.lensCanvas.addEventListener('mousedown', eLens => {
                  console.debug(eLens);
               });
               this.lensCanvas.addEventListener('mouseup', eLens => {
                  console.debug(eLens);
               });
               this.lensCanvas.addEventListener('touchstart', eLens => {
                  console.debug(eLens);
               });
               this.lensCanvas.addEventListener('touchend', eLens => {
                  console.debug(eLens);
               });
            }

            const sourceRect = this.rootElement.getBoundingClientRect();
            const targetRect = this.lensCanvas.getBoundingClientRect();

            if(e?.changedTouches?.length > 0) {
               let lastTouch = e.changedTouches[e.changedTouches.length - 1];
               if(!e.clientX || !e.clientY) {
                  e.clientX = lastTouch.clientX;
                  e.clientY = lastTouch.clientY;
               }
               if(!e.screenY || !e.screenY) {
                  e.screenX = lastTouch.screenX;
                  e.screenY = lastTouch.screenY;
               }
            }

            const relativeX = (e.clientX - sourceRect.left) / sourceRect.width;
            const relativeY = (e.clientY - sourceRect.top) / sourceRect.height;

            const targetX = targetRect.right - (relativeX * targetRect.width);
            const targetY = targetRect.top + (relativeY * targetRect.height);

            if(eventType === 'touchstart' || eventType === 'touchend'){
               /*
               this.lensCanvas.dispatchEvent(new TouchEvent(eventType, {
                  bubbles: true,
                  cancelable: true,
                  clientX: targetX,
                  clientY: targetY,
                  screenX: e.screenX,
                  screenY: e.screenY + 900,
                  altKey: e.altKey,
                  ctrlKey: e.ctrlKey,
                  shiftKey: e.shiftKey,
                  metaKey: e.metaKey,
                  changedTouches: e.changedTouches,
                  targetTouches: e.targetTouches,
                  touches: e.touches,

               }));

                */
               this.ignoreMouseEvents = true;
               let changed = e.changedTouches[0];
               eventType = eventType.replace('touchstart', 'mousedown');
               eventType = eventType.replace('touchend', 'mouseup');
               this.lensCanvas.dispatchEvent(new PointerEvent(eventType, {
                  bubbles: true,
                  cancelable: true,
                  clientX: targetX,
                  clientY: targetY,
                  screenX: changed.screenX,
                  screenY: changed.screenY + 900,
                  altKey: e.altKey,
                  ctrlKey: e.ctrlKey,
                  shiftKey: e.shiftKey,
                  metaKey: e.metaKey,
                  button: e.button,
                  buttons: e.buttons,
                  pointerId: changed.identifier,
                  pointerType: e.pointerType,

               }));
            }
            else if(!this.ignoreMouseEvents){
               this.lensCanvas.dispatchEvent(new PointerEvent(eventType, {
                  bubbles: true,
                  cancelable: true,
                  clientX: targetX,
                  clientY: targetY,
                  screenX: e.screenX,
                  screenY: e.screenY + 900,
                  altKey: e.altKey,
                  ctrlKey: e.ctrlKey,
                  shiftKey: e.shiftKey,
                  metaKey: e.metaKey,
                  button: e.button,
                  buttons: e.buttons,
                  pointerId: e.pointerId,
                  pointerType: e.pointerType,

               }));
            }
         }
      };

      this.reactRootElement = document.getElementById('bvReactRoot');
      let targetEvents = ['mousedown', 'mouseup', 'touchstart', 'touchend'];

      for(let eventType of targetEvents){
         this.rootElement.addEventListener(eventType, e => {
            dispatchPointerEvent(eventType, e);
         });

         this.reactRootElement.addEventListener(eventType, e => {
            dispatchPointerEvent(eventType, e);
         });
      }

      if(this.captureImageWidth && !this.captureImageHeight){

         let rootElementWidth = this.rootElement.clientWidth;
         let rootElementHeight = this.rootElement.clientHeight;
         let rootElementAspect = rootElementHeight / rootElementWidth;
         this.captureImageHeight = Math.floor(this.captureImageWidth / rootElementAspect);
      }
      else if(!this.captureImageWidth && this.captureImageHeight){
         window.alert('if captureImageHeight is set, captureImageWidth should be as well');
         this.captureImageWidth = this.captureImageHeight;
      }

      this.rootElement.style.display = 'none';
      this.sourceContainerElement = null;

      if(this.fixedAspectRatio){
         if(!this.width){
            this.width = Math.min(this.maxWidth, this.rootElement.clientWidth);
         }
         this.height = Math.floor(this.width / this.fixedAspectRatio);
      }

      this.delayOut = props.delayOut;
      this.onCapture = props.onCapture;
      this.recorderWebcamEventListener = props.recorderWebcamEventListener;
      this.screenshotTimer = props.screenshotTimer;
      this.ui = props.ui;
      this.onLoad = props.onLoad;
      this.customFunctions = props.customFunctions;
      this.userVariables = {};

      this.videoRecordStartTime = 0;
      this.videoRecordMaxLength = 15000;
      this.videoRecordTimeout = null;

      this.onVideoProcessedFunctions = [];

      this.videoPlayingUIRootElement = null;
      this.videoPlayingProgressRing = null;
      this.recordingPercentage = 0;

      this.codecWorker = null;
      this.isCodecWorkerRunning = false;

      this.isRunning = false;

      this.videoGroupElements = [];

      let webcamLoopInterval = 0;
      if(props.webcamFpsLimit){
         webcamLoopInterval = 1000 / props.webcamFpsLimit;
      }

      this.runWebcam = !window.BV.webcamImageUrl;

      if(!this.runWebcam){
         this.photoboothWebcam = {};
      }
      else {
         this.photoboothWebcam = new PhotoboothWebcam({
            webcamLoopInterval,
            webcamFacingMode: props.webcamFacingMode,
            customFunctions: this.customFunctions,
            isRunning: this.isRunning,
            idealCameraWidth: this.idealCameraWidth,
            idealCameraHeight: this.idealCameraHeight,
            idealCameraFPS: this.idealCameraFPS,
            cameraRotation: this.cameraRotation,
            imageProcessorMaskBlurAmount: this.imageProcessorMaskBlurAmount,
            webcamConstraints: props.webcamConstraints,
         });
      }


   }

   showLoadingOverlay(){
      this.loadingOverlay.style.display = '';
      console.debug('showing loading overlay');
   }

   hideLoadingOverlay(){
      this.loadingOverlay.style.display = 'none';
      console.debug('hiding loading overlay');
   }

   async init(){
      this.showLoadingOverlay();
      let foundSegmentation = false;
      for(let src of this.videoSources){
         if(src.type === 'webcam' && this.runWebcam){
            foundSegmentation = await this.photoboothWebcam.setupImageProcessorForSource(src);
         }
         if(foundSegmentation) break;
      }

      //this.webcamElement = null; //source video element for webcam

      this.data = [];
      this.isRecording = false;
      this.mediaRecorder = null;



      this.createRenderCanvas();

      this.setSourcesInitialized(false);

      this.hideLoadingOverlay();

      this.resizeTimeout = null;

      this.resizeObserver = new ResizeObserver(() => {
         this.onResize(0);
      });
      this.resizeObserver.observe(this.rootElement);
   }

   onResize(depth){
      let hasSizeChanged =
         Math.abs(this.rootElement.clientWidth - this.width) > 3
         || Math.abs(this.rootElement.clientHeight - this.height) > 3;

      let hasZeroVolume = this.rootElement.clientHeight === 0 || this.rootElement.clientWidth === 0;

      let aspectRatioAdjustedWidth = 0;
      let aspectRatioAdjustedHeight = 0;
      let rootElementHeight = this.rootElement.clientHeight;

      if(this.fixedAspectRatio){
         aspectRatioAdjustedHeight = Math.min(this.maxHeight, rootElementHeight);
         aspectRatioAdjustedWidth = Math.min(this.maxWidth, this.rootElement.clientWidth);
         let provisionalWidth = Math.floor(aspectRatioAdjustedHeight * this.fixedAspectRatio);
         if(provisionalWidth > this.rootElement.clientWidth){
            aspectRatioAdjustedHeight = Math.floor(aspectRatioAdjustedWidth / this.fixedAspectRatio);
         }
         else {
            aspectRatioAdjustedWidth = provisionalWidth;
         }

         hasSizeChanged = Math.abs(aspectRatioAdjustedWidth - this.width) > 3
            || Math.abs(aspectRatioAdjustedHeight - this.height) > 3;
      }

      //might want to check if the reason width/height are different is because of fixedAspectRatio
      if(hasSizeChanged && !hasZeroVolume && this.sourcesInitialized && !this.isRecording){
         if(this.resizeTimeout){
            clearTimeout(this.resizeTimeout);
         }
         this.resizeTimeout = setTimeout(async () => {
            this.stopVideos();
            let shouldHideRootElement = false;
            if(this.rootElement.style.display === 'none'){
               this.rootElement.style.display = '';
               shouldHideRootElement = true;
            }

            if(shouldHideRootElement){
               this.rootElement.style.display = 'none';
            }
            this.height = Math.min(this.maxHeight, rootElementHeight);
            this.width = Math.min(this.maxWidth, this.rootElement.clientWidth);
            if(this.fixedAspectRatio){
               this.height = aspectRatioAdjustedHeight;
               this.width = aspectRatioAdjustedWidth;
            }
            else {
               this.width = this.rootElement.clientWidth;
            }

            this.createRenderCanvas();
            this.removeAllElements();
            await this.initSources();
            await this.startVideos();


            //not entirely sure why but this gets paused when we remove the webcam canvasElements
            //it may be the browser optimizing things when nothing is being drawn.
            //regardless, we can check to make sure it's playing here
            if(this.runWebcam)
               this.photoboothWebcam.unpauseWebcam();

            this.resizeTimeout = null;
         }, 300);
      }
      else if(depth < 40){
         window.setTimeout(() => {
            this.onResize(depth + 1);
         }, 250);
      }
   }

   createRenderCanvas(){

      if(this.useOffscreenRenderCanvas) {
         if(this.renderCanvas){
            this.renderCanvas.width = this.captureImageWidth || this.width;
            this.renderCanvas.height = this.captureImageHeight || this.height;
         }
         else {
            this.renderCanvas = new OffscreenCanvas(
               this.captureImageWidth || this.width,
               this.captureImageHeight || this.height
            );
            this.renderCanvasCtx = this.renderCanvas.getContext('2d');
         }
      }
      else {
         this.renderCanvas = document.getElementById('bvPhotoboothCaptureRenderCanvas');
         if(this.renderCanvas){
            this.renderCanvas.remove();
            this.renderCanvas = null;
         }

         this.renderCanvas = document.createElement('canvas');
         this.renderCanvas.height = this.captureImageHeight || this.height;
         this.renderCanvas.width = this.captureImageWidth || this.width;
         this.renderCanvas.classList.add('render-canvas');
         this.renderCanvas.style.display = 'none';
         this.renderCanvas.setAttribute('id', 'bvPhotoboothCaptureRenderCanvas');
         document.body.append(this.renderCanvas);
         this.renderCanvasCtx = this.renderCanvas.getContext('2d');
      }
   }

   async loadSource(source, parent, zIndex){
      source.element = document.getElementById(source.idElement);

      if(!source.element && source.type === 'video') {
         await createVideoForSource(source, parent, zIndex);
      }
      else if(!source.element && source.type === 'webcam') {
         if(this.runWebcam) {
            source.element = await createWebcamForSource(
               source,
               parent,
               this.captureImageWidth || this.width,
               this.captureImageHeight || this.height,
               zIndex
            );
            this.photoboothWebcam.addCanvasElement(source.element);
         }
         else {
            source.src = window.BV.webcamImageUrl;
            source.type = 'image';
            source.classList += ' webcam-video-source';
            await createImageForSource(source, parent, zIndex);
         }
      }
      else if(!source.element && source.type === 'image') {
         await createImageForSource(source, parent, zIndex);
      }
      else if(!source.element && source.type === 'svg') {
         source.element = await createSvgForSource(source, parent, zIndex);
      }
      else if(!source.element && source.type === 'customText') {
         source.element = await createCustomTextForSource(source, parent, this.width, this.height, zIndex);
      }
      else if(!source.element){
         console.warn('photobooth: invalid source type: ' + source.type );
      }
   }

   setVideoRecordProgressRing(percentage){
      this.recordingPercentage = percentage;
      if(this.videoPlayingProgressRing){
         this.videoPlayingProgressRing.setPercentage(percentage);
      }
   }

   initVideoPlayingUI(){
      this.videoPlayingUIRootElement = document.createElement('div');
      this.videoPlayingUIRootElement.setAttribute('id', 'videoPlayingUIRoot');
      this.rootElement.append(this.videoPlayingUIRootElement);

      let button = document.createElement('div');
      button.setAttribute('id', 'videoPlayingUIStopButton');

      let square = document.createElement('div');
      square.setAttribute('id', 'videoPlayingUIStopButtonSquare');

      button.append(square);
      this.videoPlayingUIRootElement.append(button);

      let observer = new ResizeObserver(() => {
         //this.videoPlayingUIRootElement.style.display = 'none';

         let ringWidth = button.clientWidth;
         if(ringWidth <= 40){
            ringWidth = 40;
         }

         this.videoPlayingProgressRing = new ProgressRing(ringWidth);

         button.onclick = () => {
            console.log('!!!');
            //this.stopRecording();
         };
         this.setVideoRecordProgressRing(0);

         button.append(this.videoPlayingProgressRing.element);
         observer.disconnect();
      });

      observer.observe(button);


   }

   async initUI(){
      await this.loadSource(this.ui, this.rootElement);

      this.initVideoPlayingUI();


      //this could potentially use the hyparlink interaction system, but I'm keeping it simple for now.
      for(let interaction of this.ui.interactions){
         if(interaction.actionType === 'click'){
            let targetElement = document.getElementById(interaction.target);
            targetElement.addEventListener('click', async () => {
               if(interaction.eventType === 'takePhoto'){
                  this.captureScreenshot();
               }
               else if(interaction.eventType === 'startVideo'){
                  this.startRecording();
                  this.videoRecordTimeout = window.setTimeout(() => {
                     this.stopRecording();
                  }, this.videoRecordMaxLength);
               }
               else if(interaction.eventType === 'customFunction'){
                  window?.BV?.customFunctions?.[interaction.eventParams.customFunctionName]?.();
               }
               else {
                  console.warn('eventType not supported: ' + interaction.eventType);
               }
            });

         }
         else {
            console.warn('actionType not supported: ' + interaction.actionType);
         }
      }

   }

   async initWebcam(){
      if(this.runWebcam) {
         this.showLoadingOverlay();
         await this.photoboothWebcam.init(
            this.rootElement, this.width, this.height,
            this.captureImageWidth, this.captureImageHeight, this.videoSources
         );
         this.hideLoadingOverlay();
      }
   }

   async initSources(){

      await this.initWebcam();

      if(!this.sourceContainerElement){
         this.sourceContainerElement = document.createElement('div');
         this.sourceContainerElement.setAttribute('id', 'photoboothSources');
         this.sourceContainerElement.classList.add('photobooth-group');
      }
      this.rootElement.append(this.sourceContainerElement);
      if(this.fixedAspectRatio){
         this.sourceContainerElement.style.width = this.width + 'px';
         this.sourceContainerElement.style.height = this.height + 'px';
      }

      for(let i = 0; i < this.videoSources.length; i++) {
         let source = this.videoSources[i];
         //some sources can be optional (second video, etc.)
         if(source.src || source.configEditorVariableName
            || source.type === 'webcam' || source.type === 'customText'
         ) {
            await this.loadSource(source, this.sourceContainerElement, i);
         }
      }

      /*
      if(this.ui){
         await this.initUI();
      }

       */

      if(this.onLoad){
         this.onLoad(this);
      }

      this.setSourcesInitialized(true);
   }

   async run() {
      console.debug('photobooth running');
      this.rootElement.style.display = '';

      this.setIsRunning(true);

      await this.startVideos();

      setTimeout(() => {
         //this should already be playing, but safari doesn't want to draw it for some reason without the extra call
         try {
            document.querySelector('.webcam-video').style.zIndex = 1;
            //document.querySelector('.webcam-container').style.zIndex = 1;
         }
         catch (e) {
            console.warn(e);
         }
      }, 100);

      if(this.screenshotTimer) {
         setTimeout(() => {
            this.captureScreenshot();
         }, this.screenshotTimer);
      }
   }

   async renderFrame(repeat = true) {
      console.debug('beginning renderFrame()');
      if(!this.isRunning){
         return;
      }

      if(!this.useOffscreenRenderCanvas) {
         this.renderCanvas.style.display = '';
      }
      if(repeat)
         requestAnimationFrame(async () => { await this.renderFrame(this.isRecording); });

      if(!this.renderCanvas)
         return;

      let cWidth = this.renderCanvas.offsetWidth || this.renderCanvas.width;
      let cHeight = this.renderCanvas.offsetHeight || this.renderCanvas.height;

      this.renderCanvasCtx.clearRect(0, 0, this.renderCanvas.clientWidth, this.renderCanvas.clientHeight);

      if(this.flipFinalScreenshotX){
         this.renderCanvasCtx.save();
         this.renderCanvasCtx.translate(this.renderCanvas.clientWidth || this.renderCanvas.width,0);
         this.renderCanvasCtx.scale(-1,1);
      }

      for(let source of this.videoSources) {
         if(source.element) {
            try {
               await renderSourceToCanvas(source, cWidth, cHeight, this.renderCanvasCtx);
            }
            catch (e){
               console.warn(e);
            }
         }
      }

      if(this.flipFinalScreenshotX){
         this.renderCanvasCtx.restore();
      }

      if(this.isRecording){
         let elapsedTime = Date.now() - this.videoRecordStartTime;
         let percentage = Math.min(100, 100 * elapsedTime / this.videoRecordMaxLength);
         this.setVideoRecordProgressRing(percentage);

         if(this.isCodecWorkerRunning){
            this.codecWorker.postMessage({
               type: 'addFrame',
               frame: new VideoFrame(this.renderCanvas, {timestamp: elapsedTime * 1000}),
            });
         }
      }

      console.debug('finished renderFrame()');
   }

   generateScreenshotImage(saveRawImage = true){
      return new Promise(async resolve => {
         console.debug('start generateScreenshotImage()');
         if(!this.renderCanvas) {
            console.warn('attempting to save a screenshot with no render canvas');
            return;
         }

         let img;
         if(this?.customFunctions?.capturePostProcess){
            img = this.customFunctions.capturePostProcess(this.renderCanvas, this.userVariables);
         }
         else if(this.useOffscreenRenderCanvas){
            img = await this.renderCanvas.convertToBlob();
         }
         else {


            if(getBrowserName() === 'Safari' || isIOS()) {
               console.debug('On ios or safari, using standard toDataUrl');
               img = this.renderCanvas.toDataURL('image/png');
            }
            else {
               console.debug('using html2Img');
               img = await html2Img.toBlob(this.renderCanvas);
            }
         }

         if(saveRawImage) {
            await this.setRawImage();
         }


         //
         if(!img) {
            this.renderCanvas.toBlob(blob => {
               if(blob) {
                  resolve(blob);
               } else {
                  resolve();
               }
            });
         }
         else if(this.useOffscreenRenderCanvas){
            resolve(img);
         }
         else {
            if(getBrowserName() === 'Safari' || isIOS() || this?.customFunctions?.capturePostProcess) {
               console.debug('On ios or safari, or using post-processing, converting dataurl to blob');
               resolve(dataURLtoBlob(img));
            }
            else {
               resolve(img);
            }
            //
            // data url

         }
      });
   }

   async setRawImage(){
      console.debug('start setRawImage()');

      let webcamSourceElement = null;

      for(let i = 0; i < this.videoSources.length; i++){
         let source = this.videoSources[i];
         if( source.type === 'webcam') {
            webcamSourceElement = source.element;
            break;
         }
      }

      if(!webcamSourceElement) {
         console.warn('attempting to save a screenshot with no webcam canvas');
         return;
      }

      let startTime = performance.now();

      let img;
      if(getBrowserName() === 'Safari' || isIOS()) {
         console.debug('On ios or safari, using standard toDataUrl');
         img = webcamSourceElement.toDataURL('image/png');
      }
      else {
         img = await html2Img.toBlob(webcamSourceElement, {skipFonts: true});
      }

      console.debug(`Time taken to convert to data url: ${performance.now() - startTime}`);

      startTime = performance.now();

      //
      if(!img) {
         //couldn't get an image, so we just don't do anything
      }
      else {
         if(getBrowserName() === 'Safari' || isIOS()) {
            console.debug('On ios or safari, converting dataurl to blob');
            this.rawWebcamImage = dataURLtoBlob(img);
         }
         else {
            this.rawWebcamImage = img;
         }
      }

   }

   finalizePicture(img, autoAdvanceScreen = true){
      if(this.delayOut){
         setTimeout(()=>{
            this.onCapture({src: img, type: 'image/png'}, this.rawWebcamImage, autoAdvanceScreen);
         }, this.delayOut);
      }
      else {
         this.onCapture({src: img, type: 'image/png'}, this.rawWebcamImage, autoAdvanceScreen);
      }
   }

   async captureScreenshot(depth = 0, finalizePicture = true, saveRawImage = true) {
      await this.renderFrame(false);
      console.debug('captured screenshot!');

      let img = await this.generateScreenshotImage(saveRawImage);
      if(!img && depth < 10){
         requestAnimationFrame(() => this.captureScreenshot(depth + 1));
      }
      else {
         if(finalizePicture) {
            this.finalizePicture(img);
         }
         else {
            return {src: img, type: 'image/png'};
         }
      }

      if(this.customFunctions.onPhotoTaken){
         this.customFunctions.onPhotoTaken();
      }
   }

   stopVideos(){
      for(let source of this.videoSources){
         if(source.element){
            try {
               if(source.playOnRecorderStart) {
                  if(source.element.pause) {
                     source.element.pause();
                     source.element.currentTime = 0;
                  }
               }
            }
            catch (e){
               throw e;
            }
         }
      }
   }

   async startVideos(){

      let promises = [];
      console.debug('starting ' + this?.videoSources?.length + 'sources' );
      for(let source of this.videoSources){
         if(source.element) {
            let p = new Promise((resolve, reject) => {
               try {
                  if(source.playOnRecorderStart) {
                     setTimeout(() => {
                        console.debug('playing ' + source.idElement);
                        if(source.element.play) {
                           source.element.addEventListener('play', () => {
                              console.debug(source.idElement + ' started');
                              resolve();
                           }, {once: true});
                           source.element.play().catch((e) => {
                              console.warn(e);
                              console.warn(`error playing ${source.idElement} : ${source.src}`);
                              reject();
                           });
                        } else {
                           console.debug(source.idElement + ' has no "play" function');
                           resolve();
                        }
                     }, source.delayIn);
                  } else {
                     console.debug(source.idElement + ' does not play on start');
                     resolve();
                  }
               } catch (e) {
                  console.warn(e);
                  reject(e);
               }
            });
            promises.push(p);
         }
      }
      await Promise.allSettled(promises);

      await this.photoboothWebcam.onExperienceStart();

      this.sourceContainerElement.style.display = '';
   }

   runOnlyWebcam(){
      this.setIsRunning(true);
      console.log('start webcam');

      this.rootElement.style.display = '';
   }

   async startRecording() {

      if(this.videoCaptureMode === 'webCodec' && getBrowserName() === 'Chrome' && window.Worker && !isIOS()){
         this.codecWorker = new Worker('/codecWorker.js');
         this.codecWorker.onmessage = (e) => {
            if(e.data.type === 'encodeComplete'){
               let videoSrc = {src: e.data.webmBlob, type: 'video/webm'};
               this.finalizeVideoSrc(e.data.webmBlob, videoSrc, true);
            }
         };

         this.videoRecordStartTime = Date.now();
         this.codecWorker.postMessage({
            type: 'start',
            trackSettings: {
               width: this.width,
               height: this.height
            }
         });
         this.isRecording = true;


         this.hideCaptureUI();
         this.showVideoPlayingUI();
         this.isCodecWorkerRunning = true;
      }

      else {
         // create media recorder
         let videoStream = this.renderCanvas.captureStream(30);
         let mediaRecorder = new MediaRecorder(videoStream);
         this.streamSettings = videoStream.getVideoTracks()[0].getSettings();

         this.mediaRecorder = mediaRecorder;

         mediaRecorder.ondataavailable = (e) => this.ondataavailable(e);
         this.data = [];
         this.isRecording = true;


         this.hideCaptureUI();
         this.showVideoPlayingUI();

         this.videoRecordStartTime = Date.now();
         mediaRecorder.start();
         console.debug('recording start');
      }

      await this.renderFrame(true);

   }

   stopRecording(){
      console.debug('video elapsed time: ' + (Date.now() - this.videoRecordStartTime));
      if(this.videoRecordTimeout){
         clearTimeout(this.videoRecordTimeout);
      }

      this.onVideoProcessedFunctions.push(() => {
         //set a small amount of time to let everything load before changing ui to reduce flicker
         setTimeout(() => {
            this.showCaptureUI();
            this.hideVideoPlayingUI();
         }, 150);
      });

      if(this.isCodecWorkerRunning){
         this.codecWorker.postMessage({
            type: 'stop'
         });

      }
      else {
         let mediaRecorder = this.mediaRecorder;
         if(mediaRecorder?.state === 'recording') {
            mediaRecorder.stop();
         } else {
            console.debug('media recorder already stopped');
         }
      }
      this.isRecording = false;
      if(this.customFunctions.onVideoRecorded){
         this.customFunctions.onVideoRecorded();
      }
   }

   stop(bStopWebcam) {
      if(bStopWebcam && this.runWebcam){
         this.photoboothWebcam.stopWebcam();
      }
      else if(this.runWebcam){
         this.photoboothWebcam.onExperienceStop().then();
      }

      if(!this.isRunning){
         return;
      }
      console.debug('stopping photobooth');

      if(!this.useOffscreenRenderCanvas) {
         this.renderCanvas.style.display = 'none';
      }
      this.rootElement.style.display = 'none';
      this.setIsRunning(false);

      if(this.isRecording){
         this.stopRecording();
      }

      this.stopVideos();



   }

   supportsVideoType(type) {
      let video = document.querySelector('.webcam-video-source');

      // Allow user to create shortcuts, i.e. just "webm"
      let formats = {
         ogg: 'video/ogg; codecs="theora"',
         h264: 'video/mp4; codecs="avc1.42E01E"',
         webm: 'video/webm; codecs="vp8, vorbis"',
         vp9: 'video/webm; codecs="vp9"',
         hls: 'application/x-mpegURL; codecs="avc1.42E01E"'
      };

      return video.canPlayType(formats[type] || type);
   }

   finalizeVideoSrc(data, videoSrc, autoAdvanceScreen){
      this.data = data;
      this.videoSrc = videoSrc;

      if(this.delayOut){
         setTimeout(()=>{
            this.onCapture(videoSrc, videoSrc, autoAdvanceScreen);
            for(let func of this.onVideoProcessedFunctions){
               func();
            }
         }, this.delayOut);
      }
      else {
         this.onCapture(videoSrc, videoSrc, autoAdvanceScreen);
         for(let func of this.onVideoProcessedFunctions){
            func();
         }
      }
   }

   //should this be onStop and ondataavailable just records chunks?
   async ondataavailable(e) {
      console.debug('ondataavailable: Recorded chunk of size ' + e.data.size + 'B');
      let data = this.data;
      data.push(e.data);

      let videoSrc;
      if(this.supportsVideoType('h264') && getBrowserName() !== 'Safari' && !isIOS()) {
         console.debug('using webm video type');
         videoSrc = {src: new Blob(this.data, {'type': 'video/webm'}), type: 'video/webm'};
      } else {
         console.debug('using quicktime video type');
         videoSrc = {src: new Blob(this.data, {'type': 'video/quicktime'}), type: 'video/quicktime'};
      }

      if(getBrowserName() === 'Chrome' && !isIOS()){
         let decoder = new ebml.Decoder();
         let reader = new ebml.Reader();
         let inputBuffer = await videoSrc.src.arrayBuffer();
         let elms = decoder.decode(inputBuffer);
         elms.forEach((elm) => {
            reader.read(elm);
         });
         reader.stop();
         const refinedMetadataBuf = ebml.tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
         const body = inputBuffer.slice(reader.metadataSize);
         videoSrc.src = new Blob([refinedMetadataBuf, body], {type: 'video/webm'});

         this.finalizeVideoSrc(data, videoSrc, true);

      }
      else {
         this.finalizeVideoSrc(data, videoSrc, true);
      }

   };

   removeAllElements(){
      while(this.sourceContainerElement.firstChild) {
         this.sourceContainerElement.removeChild(this.sourceContainerElement.firstChild);
      }

      if(this.runWebcam)
         this.photoboothWebcam.resetCanvasElements();

      this.setSourcesInitialized(false);
   }

   removeAllElementsExceptWebcam(){
      let children = this.sourceContainerElement.children;
      for(let i = 0; i < children.length; ){
         if(!children[i].classList.contains('webcam-video-source')){
            this.sourceContainerElement.removeChild(children[i]);
         }
         else {
            i++;
         }
      }

      if(this.runWebcam)
         this.photoboothWebcam.resetCanvasElements();

      this.setSourcesInitialized(false);
   }

   updateSourceWithVariableSourceName(key, value){
      let hasVideoChanged = false;
      for(let source of this.videoSources){
         if(key === 'lensId' && source.type === 'webcam'){
            window?.BV?.lensIntegration?.loadLensById?.(value);
         }

         if(source.variableSourceName && source.variableSourceName === key){
            source.src = value;
            hasVideoChanged = true;
         }
      }
      return hasVideoChanged;
   }

   async updateUserVariable(variableName, src){

      if(this.isLoading){
         throw 'Still loading previous';
      }
      this.isLoading = true;

      this.userVariables[variableName] = src;

      let hasVideoChanged = this.updateSourceWithVariableSourceName(variableName, src);

      if(hasVideoChanged){
         this.removeAllElementsExceptWebcam();
         await this.initSources();
      }

      this.isLoading = false;

   }

   async updateUserVariables(dict){

      if(this.isLoading){
         throw 'Still loading previous';
      }
      this.isLoading = true;


      let hasVideoChanged = false;
      for(let key of Object.keys(dict)){
         this.userVariables[key] = dict[key];
         let foundSource = this.updateSourceWithVariableSourceName(key, dict[key]);
         hasVideoChanged = hasVideoChanged || foundSource;
      }

      if(hasVideoChanged){
         this.removeAllElementsExceptWebcam();
         await this.initSources();
      }

      this.isLoading = false;

   }

   async clearUserVariables(){
      this.userVariables = {};
      for(let source of this.videoSources){
         if(source.variableSourceName){
            source.src = '';
         }
      }
   }

   hideCaptureUI(){
      let captureUI = document.getElementById('photoboothCaptureUI');
      if(captureUI){
         captureUI.style.display = 'none';
      }
   }

   showCaptureUI(){
      let captureUI = document.getElementById('photoboothCaptureUI');
      if(captureUI){
         captureUI.style.display = '';
      }
   }

   hideVideoPlayingUI(){
      if(this.videoPlayingUIRootElement){
         this.videoPlayingUIRootElement.style.display = 'none';
      }
   }

   showVideoPlayingUI(){
      if(this.videoPlayingUIRootElement){
         this.videoPlayingUIRootElement.style.display = '';
      }
   }

   setSourcesInitialized(val){
      this.sourcesInitialized = val;
      if(this.runWebcam)
         this.photoboothWebcam.setSourcesInitialized(val);
   }

   setIsRunning(val){
      this.isRunning = val;
      if(this.runWebcam)
         this.photoboothWebcam.setIsRunning(val);
   }

   delete(){
      this.stopVideos();
      this.setSourcesInitialized(false);
      this.resizeObserver.disconnect();

      if(this.runWebcam)
         this.photoboothWebcam.stopWebcam();

      while(this.rootElement.firstChild){
         this.rootElement.removeChild(this.rootElement.firstChild);
      }
   }

   disconnectFromRootElement(){
      if(this.rootElement){
         this.resizeObserver.unobserve(this.rootElement);
         this.rootElement = null;
      }
   }

   setRootElement(element){
      if(this.rootElement){
         this.disconnectFromRootElement();
      }
      this.rootElement = element;
      let aspectRatioAdjustedWidth = 0;
      let aspectRatioAdjustedHeight = 0;
      let rootElementHeight = this.rootElement.clientHeight;

      if(this.fixedAspectRatio){
         aspectRatioAdjustedHeight = Math.min(this.maxHeight, rootElementHeight);
         aspectRatioAdjustedWidth = Math.min(this.maxWidth, this.rootElement.clientWidth);
         let provisionalWidth = Math.floor(aspectRatioAdjustedHeight * this.fixedAspectRatio);
         if(provisionalWidth > this.rootElement.clientWidth){
            aspectRatioAdjustedHeight = Math.floor(aspectRatioAdjustedWidth / this.fixedAspectRatio);
         }
         else {
            aspectRatioAdjustedWidth = provisionalWidth;
         }
         this.width = aspectRatioAdjustedWidth;
         this.height = aspectRatioAdjustedHeight;
      }
      this.resizeObserver.observe(element);
   }

   async takeInstantPicture(saveRawImage){
      await this.run();
      return new Promise(resolve => {
         //half second timeout so safari can make everything visible in time so it will actually draw
         setTimeout(async () => {
            let image = await this.captureScreenshot(0, false, saveRawImage);
            await this.finalizePicture(image.src, false);
            this.stop();
            resolve();
         },2000);
      });
   }

   async clearImageData(){
      this.rawWebcamImage = null;
      await this.onCapture({src: null, type: 'null'}, null, false);
   }

   getCurrentWebcamSettings(){
      return this.photoboothWebcam.currentWebcamSettings;
   }

}

