import { v4 as uuidv4 } from 'uuid';
import {ScreenType} from './dataTypes.js';

/**
 * @class FormstackField
 * @property {string} idField
 * @property {string} valueType
 * @property {string} valueDescriptor
 */

/**
 * @class FormstackEventConfig
 * @property {string} idForm
 * @property {FormstackField[]} fields
 * @property {boolean} requirementVariable
 */

/**
 * @class FormstackConfig
 * @property {string} apiToken
 * @property {FormstackEventConfig} genericConfig
 * @property {FormstackEventConfig} onEmailConfig
 * @property {FormstackEventConfig} onSmsConfig
 *
 */
export class FormstackConfig{
   constructor(props) {
      this.apiToken = props.apiToken;
      this.genericConfig = props.genericConfig;
      this.onEmailConfig = props.onEmailConfig;
      this.onSmsConfig = props.onSmsConfig;
   }
}

/**
 * @class FormstackConfig
 * @property {string} siteId
 * @property {string} emailClickedEventId
 * @property {string} smsClickedEventId
 * @property {string} startId
 *
 */
export class FathomConfig{
   constructor(props) {
      this.siteId = props.siteId;
      this.emailClickedEventId = props.emailClickedEventId;
      this.smsClickedEventId = props.smsClickedEventId;
      this.startId = props.startId;
   }
}

/**
 * @class PhotoboothCustomElement
 * @property {string} class
 * @property {boolean} generateSrcExtension - use for video, if true will append .mov for apple and .webm for others
 * @property {string} id
 * @property {string} innerText
 * @property {number} key
 * @property {string} onClickCustomFunction
 * @property {number} onClickGoToPageNumber
 * @property {string} src
 * @property {string} type
 * @property {string} uuid
 * @property {string} configEditorVariableName
 */
export class PhotoboothCustomElement{
   constructor(props) {
      Object.assign(this, props);
      if(!this.type){
         this.type = 'text';
      }
      if(!this.uuid){
         this.uuid = uuidv4();
      }
   }
}

/**
 * @class WelcomeScreenScript
 * @extends PhotoboothScreen
 * @property {PhotoboothCustomElement[]} customButtons
 */

/**
 * @class TextFormField
 * @property {string} id
 * @property {string} inputClass
 * @property {boolean} isRequired
 * @property {string} label
 * @property {string} labelClass
 * @property {string} type
 * @property {string} variableName
 */
export class TextFormField{
   constructor(props) {
      Object.assign(this, props);

      this.isRequired = this.isRequired ?? true;

      if(!this.type){
         this.type = 'email';
      }

      if(!this.uuid){
         this.uuid = uuidv4();
      }

      if(!this.id){
         this.id = this.uuid;
      }
   }

}

/**
 * @class AgreeCheckbox
 * @property {string} checkedImageUrl
 * @property {boolean} defaultValue
 * @property {boolean} isOptional
 * @property {string} text
 * @property {string} uncheckedImageUrl
 * @property {string} uuid
 * @property {string} variableName
 */
export class AgreeCheckbox{
   constructor(props) {
      this.defaultValue = props.defaultValue ?? false;
      this.isOptional = props.isOptional;
      this.text = props.text;
      this.uuid = props.uuid || uuidv4();
      this.variableName = props.variableName || '';
      this.checkedImageUrl = props.checkedImageUrl;
      this.uncheckedImageUrl = props.uncheckedImageUrl;
   }
}

/**
 * @class TermsAndConditions
 * @property {string} text
 * @property {string} url
 * @property {string} uuid
 */
export class TermsAndConditions{
   constructor(props) {
      this.text = props.text;
      this.url = props.url;
      this.uuid = props.uuid || uuidv4();
   }
}

/**
 * @class TextFormScreenScript
 * @extends PhotoboothScreen
 * @property {AgreeCheckbox[]} agreeCheckboxes
 * @property {Object[]} textFormFields
 * @property {Object[]} termsAndConditions
 */

/**
 * @class BlankScreenScript
 * @extends PhotoboothScreen
 */

/**
 * @class UserSelectionOptionValue
 * @property {boolean} appendVideoExtensionToValue
 * @property {number} numRandomItems -eg, if there are 2 player videos => playerVideo1.webm, playerVideo2.webm
 * @property {string} uuid
 * @property {string} value
 *
 */
export class UserSelectionOptionValue{
   constructor(props) {
      this.appendVideoExtensionToValue = props.appendVideoExtensionToValue ?? true;
      this.numRandomItems = props.numRandomItems;
      this.value = props.value || '';
      this.uuid = props.uuid || uuidv4();
   }
}

/**
 * @class TargetElement
 * @property {string} id
 * @property {string} uuid
 *
 */
export class TargetElement{
   constructor(props) {
      this.idElement = props.idElement ?? '';
      this.uuid = props.uuid || uuidv4();
   }
}

/**
 * @class TargetElementGroup
 * @property {boolean} isRequired
 * @property {TargetElement[]} items
 * @property {string} uuid
 *
 */
export class TargetElementGroup{
   constructor(props) {
      this.items = props.items ?? [];
      this.items = this.items.map(x => new TargetElement({...x}));
      this.isRequired = props.isRequired ?? false;
      this.uuid = props.uuid || uuidv4();
   }
}

/**
 * @class UserSelectionOption
 * @property {boolean} appendVideoExtensionToOnSelectSrc
 * @property {boolean} disabled
 * @property {boolean} hidden
 * @property {boolean} isArchived
 * @property {string} playerName
 * @property {string} onSelectVideoSrc
 * @property {string} previewImageSrc
 * @property {string} selectedPreviewImageSrc
 * @property {string} uuid
 * @property {UserSelectionOptionValue[][]} values
 */
export class UserSelectionOption{
   constructor(props) {
      this.appendVideoExtensionToOnSelectSrc = props.appendVideoExtensionToOnSelectSrc ?? true;
      this.appendVideoExtensionToValue = props.appendVideoExtensionToValue ?? true;
      this.disabled = props.disabled ?? false;
      this.hidden = props.hidden ?? false;
      this.isArchived = props.isArchived ?? false;
      this.playerName = props.playerName ?? '';
      this.onSelectVideoSrc = props.onSelectVideoSrc || '';
      this.previewImageSrc = props.previewImageSrc || '';
      this.selectedPreviewImageSrc = props.selectedPreviewImageSrc || '';

      this.uuid = props.uuid || uuidv4();
      this.value = props.value || '';
      this.values = props.values;
      if(!this.values){
         this.values = [
            new UserSelectionOptionValue({
               appendVideoExtensionToValue: props.appendVideoExtensionToValue ?? true,
               value: this.value || ''
            })
         ];
      }
      if(!Array.isArray(this.values[0])){
         this.values = [this.values.map(x => new UserSelectionOptionValue({...x}))];
      }
      else {
         this.values = this.values.map(x => {
            return x.map(y => new UserSelectionOptionValue({...y}));
         });
      }

   }
}

/**
 * @class UserSelectionScreen
 * @extends PhotoboothScreen
 * @property {UserSelectionOption[]} options
 * @property {TargetElement[][]} targetElements
 * @property {boolean} canOwnerCreateOption
 *
 */

/**
 * @class QRConfirmScreen
 * @extends PhotoboothScreen
 * @property {boolean} requireContactVerification
 * @property {color} qrDarkColor
 * @property {color} qrLightColor
 *
 */

/**
 * @class ManualCaptureScreen
 * @extends PhotoboothScreen
 * @property {string} manualCaptureScreenRetakeButtonUrl
 * @property {string} manualCaptureScreenRetakeText
 * @property {string} manualCaptureScreenTakeButtonUrl
 * @property {string} manualCaptureScreenTakeText
 *
 */

/**
 * @class ContactInfoIntegratedFormScreen
 * @extends PhotoboothScreen
 * @property {string} contactInfoMethod
 *
 */

/**
 * @class ReviewScreenScript
 * @extends PhotoboothScreen
 * @property {string} emailButtonImageUrl
 * @property {number} emailButtonNextIndex
 * @property {string} emailButtonText
 * @property {boolean} hideTapHoldButtonWhenPossible
 * @property {string} previewImageFrame
 * @property {string} retakePhotoButtonImageUrl
 * @property {string} retakePhotoText
 * @property {string} regeneratePhotoButtonImageUrl
 * @property {string} regeneratePhotoText
 * @property {string} saveButtonImageUrl
 * @property {string} saveText
 * @property {string} shareButtonImageUrl
 * @property {string} shareItemText
 * @property {string} shareItemTitle
 * @property {string} shareText
 * @property {string} smsButtonImageUrl
 * @property {number} smsButtonNextIndex
 * @property {string} smsButtonText
 * @property {boolean} webSubmitSessionOnLoad
 *
 */

/**
 * @class PhotoboothScreen
 * @property {PhotoboothCustomElement[]} backgroundElements
 * @property {string} backButtonImageUrl
 * @property {string} backButtonText
 * @property {string} backgroundImageUrl
 * @property {number} backScreenIndex
 * @property {PhotoboothCustomElement[]} elements
 * @property {string} nextButtonImageUrl
 * @property {string} nextButtonText
 * @property {number} nextScreenIndex
 * @property {string} pdfUrl
 * @property {boolean} showOnScreenKeyboard
 * @property {number} styles
 * @property {number} type
 * @property {string} uuid
 *
 */
export class PhotoboothScreen{
   constructor(props) {
      Object.assign(this, props);

      if(!this.type){
         this.type = ScreenType.Blank;
      }

      this.backgroundElements = this.backgroundElements || [];
      this.backgroundElements = this.backgroundElements.map(x => new PhotoboothCustomElement(x));

      this.elements = this.elements || [];
      this.elements = this.elements.map(x => new PhotoboothCustomElement(x));

      if(!this.uuid){
         this.uuid = uuidv4();
      }

      if(this.type === ScreenType.UserSelection){
         if(!this.options){
            this.options = [];
         }
         this.options = this.options.map(
            x => {
               x.values = x.values.slice(0, this.targetElements.length);
               return x.constructor.name === 'UserSelectionOption' ? x : new UserSelectionOption(x);
            }
         );

         if(!this.targetElements){
            if(this.targetElementId){
               this.targetElements = [
                  new TargetElementGroup({items: [new TargetElement({idElement: this.targetElementId})]})
               ];
            }
            else {
               this.targetElements = [[]];
            }
         }

         if(!this.targetElements[0].items){
            this.targetElements = [new TargetElementGroup({items: this.targetElements})];
         }

         this.canOwnerCreateOption = props.canOwnerCreateOption;

      }

      if(this.type === ScreenType.TextForm || this.type === ScreenType.ContactInfoIntegratedFormScreen){
         this.textFormFields = this.textFormFields || this.fields || [];
         this.textFormFields = this.textFormFields.map(x => new TextFormField(x));
         this.showOnScreenKeyboard = this.showOnScreenKeyboard ?? false;
         this.agreeCheckboxes = this.agreeCheckboxes ?? [];
         this.agreeCheckboxes = this.agreeCheckboxes.map(x => new AgreeCheckbox({...x}));
         this.termsAndConditions = this.termsAndConditions ?? [];
         if(!Array.isArray(this.termsAndConditions)){
            this.termsAndConditions = [this.termsAndConditions];
         }
      }

      if(this.type === ScreenType.ContactInfoIntegratedFormScreen){
         this.contactInfoMethod = props.contactInfoMethod;
      }

      if(this.type === ScreenType.Review){
         this.webSubmitSessionOnLoad = props.webSubmitSessionOnLoad;
         this.hideTapHoldButtonWhenPossible = this.hideTapHoldButtonWhenPossible ?? true;
         this.tapHoldBtnURL = this.tapHoldBtnURL || '';
      }

      if(this.type === ScreenType.QRConfirmScreen){
         this.requireContactVerification = props.requireContactVerification;
         this.qrDarkColor = props.qrDarkColor;
         this.qrLightColor = props.qrLightColor;
      }

      if(this.type === ScreenType.ManualCaptureScreen){
         this.manualCaptureScreenRetakeButtonUrl= props.manualCaptureScreenRetakeButtonUrl;
         this.manualCaptureScreenRetakeText = props.manualCaptureScreenRetakeText;
         this.manualCaptureScreenTakeButtonUrl = props.manualCaptureScreenTakeButtonUrl;
         this.manualCaptureScreenTakeText = props.manualCaptureScreenTakeText;
         this.manualCaptureAdvanceScreenOnPicture = props.manualCaptureAdvanceScreenOnPicture;
      }

      this.pdfUrl = props.pdfUrl;

   }

}

/**
 * @class PhotoboothSourceFilter
 * @property {string} name
 */
export class PhotoboothSourceFilter{
   constructor(props) {
      this.name = props.name;
   }
}

/**
 * @class PhotoboothSource
 * @property {boolean} captureInRecording
 * @property {string} classList - space separated classes
 * @property {string} configEditorVariableName
 * @property {string} customTextAlign
 * @property {string} customTextFillColor
 * @property {string} customTextFont
 * @property {string} customTextHeight
 * @property {string} customTextString
 * @property {string} customTextStrokeColor
 * @property {string} customTextTransform
 * @property {string} customTextWidth
 * @property {string} customTextX
 * @property {string} customTextY
 * @property {number} delayIn - Time delay in ms before the video starts (only applicable for type: video)
 * @property {PhotoboothSourceFilter[]} filters
 * @property {boolean} flipX
 * @property {boolean} flipXPreview
 * @property {boolean} flipXFinal
 * @property {boolean} generateSrcExtension
 * @property {string} idElement
 * @property {boolean} isForeground
 * @property {string} lensId
 * @property {boolean} lensIsPushToWeb
 * @property {string} lensGroupId
 * @property {string} lensApiToken
 * @property {boolean} lensUseBackCamera
 * @property {boolean} loop - should video loop (only applicable for type: video)
 * @property {boolean} onlyVisibleInRecording
 * @property {boolean} playOnRecorderStart
 * @property {string} segmentationType - selfie or body segmentation
 * @property {string} src - full source of image/video
 * @property {string} type - video, image, or webcam
 * @property {boolean} useLensIntegration
 * @property {string} uuid
 * @property {string} variableSourceName
 */
export class PhotoboothSource{
   constructor(props) {
      this.captureInRecording = props.captureInRecording ?? true;
      this.classList = props.classList || '';
      this.delayIn = props.delayIn || 0;
      this.generateSrcExtension = props.generateSrcExtension ?? true;
      this.idElement = props.idElement || '';
      this.loop = props.loop ?? false;
      this.onlyVisibleInRecording = props.onlyVisibleInRecording ?? false;
      this.playOnRecorderStart = props.playOnRecorderStart ?? true;
      this.src = props.src || '';
      this.type = props.type || 'video';
      this.variableSourceName = props.variableSourceName || '';
      this.configEditorVariableName = props.configEditorVariableName || '';
      this.isForeground = props.isForeground;
      this.segmentationType = props.segmentationType;

      this.customTextString = props.customTextString;
      this.customTextX = props.customTextX;
      this.customTextY = props.customTextY;
      this.customTextWidth = props.customTextWidth;
      this.customTextHeight = props.customTextHeight;
      this.customTextFont = props.customTextFont;
      this.customTextFillColor = props.customTextFillColor;
      this.customTextStrokeColor = props.customTextStrokeColor;
      this.customTextTransform = props.customTextTransform;
      this.customTextAlign = props.customTextAlign;

      this.filters = props.filters || [];

      //migration of old flipX variable to the new split ones;
      if(props.flipX !== undefined){
         if(this.type === 'image'){
            if(props.flipX){
               this.flipXFinal = true;
               this.flipXPreview = false;
            }
            else{
               this.flipXFinal = false;
               this.flipXPreview = false;
            }
         }
         else{
            this.flipXPreview = this.flipXFinal = props.flipX;
         }
      }
      else{
         this.flipXPreview = props.flipXPreview ?? false;
         this.flipXFinal = props.flipXFinal ?? false;
      }

      this.useLensIntegration = props.useLensIntegration;
      this.lensId = props.lensId;
      this.lensGroupId = props.lensGroupId;
      this.lensUseBackCamera = props.lensUseBackCamera;
      this.lensIsPushToWeb = props.lensIsPushToWeb;
      this.lensApiToken = props.lensApiToken



      this.uuid = props.uuid || uuidv4();
   }
}

/**
 * @class PhotoboothSourceGroup
 * @property {PhotoboothSource[]} list
 */
export class PhotoboothSourceGroup{
   constructor(props) {
      //backwards compatibility for Topps, may be able to remove
      if(Array.isArray(props)){
         this.list = props;
      }
      else {
         this.list = props.list || [];
      }
      this.list = this.list.map(x => new PhotoboothSource(x));
      this.uuid = props.uuid || uuidv4();
   }
}

/**
 * @class
 * @property {string[]} additionalCssFiles
 * @property {string[]} additionalJsFiles
 * @property {number} appAspectRatio
 * @property {string} baseUrl
 * @property {string} blinkDomainId
 * @property {string} blinkEmail
 * @property {string} blinkRefreshToken
 * @property {number} cameraRotation - currently must be PI/2 or 3PI/2
 * @property {number} captureImageHeight
 * @property {number} captureImageWidth
 * @property {object} captureUI
 * @property {number} configScreenIndex
 * @property {string} countdownVideoUrl
 * @property {number} delayOut - time (in ms) after the picture is taken before stopping videos and moving on
 * @property {number} photoTimer - time (in ms), from start for a picture to get taken. No timer is null or undefined
 * @property {number} fixedAspectRatio
 * @property {boolean} flipFinalScreenshotX
 * @property {boolean} finalMediaIsVideo
 * @property {number} idealCameraHeight
 * @property {number} idealCameraWidth
 * @property {number} imageProcessorMaskBlurAmount
 * @property {number} inactivityTimeLimit
 * @property {date} lastUpdated
 * @property {string} messageTitle
 * @property {number} minWidth
 * @property {string} s3Bucket - required for kiosk to work offline, combines with s3Folder to make the baseUrl
 * @property {string} s3Folder - required for kiosk to work offline, combines with s3Bucket to make the baseUrl
 * @property {PhotoboothScreen[]} screens
 * @property {string} smsText
 * @property {string} shareItemTitle
 * @property {string} shareItemText
 * @property {PhotoboothSource[]} sources
 * @property {string} videoCaptureMode
 * @property {string} webcamFacingMode
 * @property {number} webcamFpsLimit
 * @property {object} backgroundVariables
 * @property {object} logoVariables
 * @property {object} overlayVariables
 */
export class PhotoboothScript{
   constructor(props) {
      this.baseUrl = props.baseUrl;
      this.photoTimer = props.photoTimer;
      this.fixedAspectRatio = props.fixedAspectRatio;
      this.appAspectRatio = props.appAspectRatio;

      let regexMatches = [];
      if(props.baseUrl) {
         regexMatches = props.baseUrl.match(/https:\/\/(.*).s3.amazonaws.com\/(.*)/) || [];
      }
      if(regexMatches.length === 3){
         this.s3Bucket = regexMatches[1];
         this.s3Folder = regexMatches[2];
      }
      else {
         this.s3Bucket = props.s3Bucket;
         this.s3Folder = props.s3Folder;
      }
      this.maxWidth = props.maxWidth;
      this.inactivityTimeLimit = props.inactivityTimeLimit;
      this.countdownVideoUrl = props.countdownVideoUrl;
      this.delayOut = props.delayOut;
      this.smsText = props.smsText;
      this.shareItemTitle = props.shareItemTitle;
      this.shareItemText = props.shareItemText;
      this.messageTitle = props.messageTitle;
      this.webcamFpsLimit = props.webcamFpsLimit ?? 0;
      this.videoCaptureMode = props.videoCaptureMode;
      this.formstackConfig = props.formstackConfig;
      this.fathomConfig = props.fathomConfig;
      this.captureUI = props.captureUI;
      this.webcamFacingMode = props.webcamFacingMode;
      this.flipFinalScreenshotX = props.flipFinalScreenshotX;
      this.finalMediaIsVideo = props.finalMediaIsVideo;
      this.backgroundVariables = props.backgroundVariables;
      this.logoVariables = props.logoVariables;
      this.overlayVariables = props.overlayVariables;

      this.enableAI = props.enableAI;
      this.captureImageWidth = props.captureImageWidth;
      this.captureImageHeight = props.captureImageHeight;

      this.blinkDomainId = props.blinkDomainId;
      this.blinkEmail = props.blinkEmail;
      this.blinkRefreshToken = props.blinkRefreshToken;

      this.configScreenIndex = props.configScreenIndex;

      this.screens = [];
      if(props.screens) {
         for(let screen of props.screens) {
            this.screens.push(new PhotoboothScreen(screen));
         }
      }

      this.sources = props.sources || [];

      //backward compatability for source groups, which we are getting rid of.
      if(this.sources[0] && this.sources[0].list){
         this.sources = this.sources[0].list;
      }
      this.sources = this.sources.map(x => new PhotoboothSource(x));

      this.additionalCssFiles = props.additionalCssFiles || [];
      this.additionalJsFiles = props.additionalJsFiles || [];

      this.idealCameraWidth = props.idealCameraWidth;
      this.idealCameraHeight = props.idealCameraHeight;

      this.imageProcessorMaskBlurAmount = props.imageProcessorMaskBlurAmount;

      this.cameraRotation = props.cameraRotation || 0;

      this.webcamSettings = props.webcamSettings || {exposureTime: [], brightness: []};

      this.lastUpdated = props.lastUpdated;

   }

}