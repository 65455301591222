
import {SelfieSegmentationStage} from './imageProcessorStages/selfieSegmentationStage.js';

import '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-converter';
// Register WebGL backend.
import '@tensorflow/tfjs-backend-webgl';
import {ImageProcessor} from './imageProcessor.js';
import {PosenetSegmentation} from './imageProcessorStages/posenetSegmentation.js';
import {ColorLevelSet} from './imageProcessorStages/colorLevelSet.js';
import {BodySegmentation} from './imageProcessorStages/bodySegmentation.js';
import {LensIntegration} from './imageProcessorStages/lensIntegration.js';
import {parse} from "uuid";
//import {Xr8Integration} from './imageProcessorStages/xr8Integration.js';

window.TMP = {
   xMagnitude: -1,
   yMagnitude: 0,
   rot: Math.PI / 2,
};

export class PhotoboothWebcam{
   constructor(props) {

      this.domElement = props.webcamElement;
      this.canvasElements = []; // canvas elements getting copies of webcam data. needed for safari
      this.webcamLoopInterval = props.webcamLoopInterval || 0;
      this.webcamFacingMode = props.webcamFacingMode;
      this.customFunctions = props.customFunctions || {};
      this.lastDrawTime = Date.now();

      this.idealCameraWidth = props.idealCameraWidth || 3840;
      this.idealCameraHeight = props.idealCameraHeight || 2160;
      this.idealCameraFPS = props.idealCameraFPS || 30;

      this.imageProcessorMaskBlurAmount = props.imageProcessorMaskBlurAmount || 2;

      this.cameraRotation = props.cameraRotation || 0;
      this.cameraRotationXOffsetMagnitude = Math.sign(Math.cos(this.cameraRotation)) === 1 ? 1 : 0;
      this.cameraRotationYOffsetMagnitude = Math.sign(Math.cos(this.cameraRotation)) === -1 ? 1 : 0;

      this.sourcesInitialized = props.sourcesInitialized || false;
      this.isRunning = props.isRunning || false;

      this.bodySegmentation = null;

      this.imageProcessor = new ImageProcessor();
      this.selfieSegmentation = null;
      this.bodySegmenter = null;
      this.posenet = null;

      this.intermediateCanvas = null;

      this.webcamConstraints = props.webcamConstraints;
      this.currentWebcamSettings = {};

   }

   async init(rootElement, width, height, captureImageWidth, captureImageHeight, videoSources){
      this.initDomElement();

      //this.createCameraRotationControl();

      rootElement.append(this.domElement);

      if(this.domElement.paused || this.domElement.currentTime === 0){
         await this.startWebcam();
         this.calculateWebcamCanvasDrawOptions(
            width, height,
            captureImageWidth, captureImageHeight
         );


         for(let source of videoSources){
            if(source.imageProcessor){
               source.imageProcessor.onWebcamStart(this.domElement);
            }
            else if(source.filters.length > 0 && source.filters[0].name === 'colorBalance'){
               let colorBalance = new ColorLevelSet();
               await colorBalance.findLevels(this.domElement);
            }
         }
         if(this?.imageProcessor?.pipeline?.length > 0) {
            //the first run of the image processor may block main thread, so give the window a chance to show
            //the loading screen before continuing.
            await new Promise(resolve => setTimeout(() => resolve(), 100));
            this.mainLoopDraw();
         }
         this.mainLoop();
      }
   }

   initDomElement(){
      if(!this.domElement) {
         let webcam = document.createElement('video');
         webcam.setAttribute('height', '2');
         webcam.classList.add('webcam-video-source');
         webcam.setAttribute('muted', '');
         webcam.setAttribute('playsInline', '');
         this.domElement = webcam;
      }
   }

   mainLoopDraw(){
      if(this.imageProcessor.pipeline.length === 0){
         this.drawToCanvasElements(this.domElement);
      }
      else {
         this.drawToIntermediateCanvas(this.domElement);
         this.imageProcessor.run(this.intermediateCanvas, this.domElement);
      }
   }

   mainLoop(runOnce = false){
      if(!runOnce) {
         requestAnimationFrame(() => this.mainLoop());
      }

      let now = Date.now();
      if(now - this.lastDrawTime > this.webcamLoopInterval) {
         this.lastDrawTime = now;

         if(this.sourcesInitialized && this.isRunning && this.canvasElements.length > 0) {
            this.mainLoopDraw();
         }
      }
   }

   calculateWebcamCanvasDrawOptions(width, height, captureImageWidth, captureImageHeight){
      let scaledCanvasX = this.domElement.videoHeight * width / height;
      let scaledCanvasY = this.domElement.videoHeight;
      if(scaledCanvasX > this.domElement.videoWidth) {
         scaledCanvasX = this.domElement.videoWidth;
         scaledCanvasY = this.domElement.videoWidth * height / width;
      }

      if(scaledCanvasY > this.domElement.videoHeight) {
         scaledCanvasY = this.domElement.videoHeight;
      }

      let sx = Math.round((this.domElement.videoWidth - scaledCanvasX) / 2.0);
      let sy = 0;
      let dx = 0;
      let dy = 0;

      let rotateXOffset = 0;
      let rotateYOffset = 0;
      let dWidth = parseInt(captureImageWidth || width);
      let dHeight = parseInt(captureImageHeight || height);
      let sWidth = scaledCanvasX;
      let sHeight = scaledCanvasY;

      if(this.cameraRotation){
         rotateXOffset = dWidth; //this.signCosCameraRotation;
         rotateYOffset = 0;//dHeight / 2;// 0;

         rotateXOffset = rotateXOffset * (1 - Math.cos(this.cameraRotation));

         //This is really hacky and there must be something better, but it works for now
         if(this.cameraRotation > Math.PI / 2 && this.cameraRotation <= Math.PI) {
            rotateXOffset = this.domElement.videoHeight;
            rotateYOffset = dHeight * (1 - Math.sin(this.cameraRotation));
         }
         else if(this.cameraRotation > Math.PI && this.cameraRotation < Math.PI * 3 / 2) {
            rotateYOffset = dHeight;
            rotateXOffset = this.domElement.videoHeight * (Math.abs(Math.cos(this.cameraRotation)));
         }
         else if(this.cameraRotation >= Math.PI * 3 / 2 && this.cameraRotation < 2 * Math.PI) {
            rotateYOffset = dHeight * (Math.abs(Math.sin(this.cameraRotation)));
            rotateXOffset = 0;
         }
         else {
            rotateYOffset = 0;
         }

         let finalWidth = dWidth;
         let finalHeight = dHeight;


         if(dHeight > dWidth){
            sWidth = sWidth + Math.abs(Math.sin(this.cameraRotation)) * (this.domElement.videoWidth - sWidth);
            sHeight = sHeight + Math.abs(Math.sin(this.cameraRotation)) * (this.domElement.videoHeight - sHeight);

            sx = sx - Math.abs(Math.sin(this.cameraRotation)) * (sx);
            sy = sy - Math.abs(Math.sin(this.cameraRotation)) * (sy);

            finalWidth = dWidth + Math.abs(Math.sin(this.cameraRotation)) * (dHeight - dWidth);
            finalHeight = dHeight - Math.abs(Math.sin(this.cameraRotation)) * (dHeight - dWidth);
            dWidth = finalWidth;
            dHeight = finalHeight;
         }
         [dx, dy, dWidth, dHeight] = this.applyRotationLUT(this.cameraRotation, dx, dy, finalWidth, finalHeight);
      }

      this.webcamCanvasDrawOptions = {
         sx, sy, sWidth, sHeight,
         dx, dy, dWidth, dHeight,
         cameraRotation: this.cameraRotation, cameraRotationXOffsetMagnitude: this.cameraRotationXOffsetMagnitude,
         cameraRotationYOffsetMagnitude: this.cameraRotationYOffsetMagnitude,
         imageProcessorMaskBlurAmount: this.imageProcessorMaskBlurAmount,
         rotateXOffset, rotateYOffset,width, height, captureImageWidth, captureImageHeight
      };

      let imageProcessorDrawingOptions = {
         ...this.webcamCanvasDrawOptions
      };
      this.imageProcessor.setDrawingOptions(imageProcessorDrawingOptions);

      if(!this.intermediateCanvas){
         this.intermediateCanvas = document.createElement('canvas');
         this.intermediateCanvas.width = captureImageWidth || width;
         this.intermediateCanvas.height = captureImageHeight || height;
         //document.body.append(this.intermediateCanvas);
      }
   }

   async startWebcam(){
      if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
         // Not adding `{ audio: true }` since we only want video now
         let constraints = {};
         let selectedWebcam =  localStorage.getItem('selectedWebcam');
         if(selectedWebcam){
            console.debug('using previously selected webcam');
            constraints = {
               video: {
                  deviceId: {exact: selectedWebcam},
                  width: {ideal: this.idealCameraWidth},
                  height: {ideal: this.idealCameraHeight},
                  frameRate: {ideal: this.idealCameraFPS},
               }
            };
         }
         else {
            constraints = {
               video: {
                  width: {ideal: this.idealCameraWidth},
                  height: {ideal: this.idealCameraHeight},
                  frameRate: {ideal: this.idealCameraFPS},
                  facingMode: this.webcamFacingMode || 'user',
               }
            };
         }
         try {
            this.domElement.srcObject = await navigator.mediaDevices.getUserMedia( constraints );
            await this.domElement.play();
            await this.imageProcessor.onWebcamStart(this.domElement);
            console.log('Started webcam');
            let videoTrack = this.domElement.srcObject?.getVideoTracks?.()[0];
            if(videoTrack){
               await this.updateVideoTrackConstraints();
               let videoSettings = videoTrack.getSettings?.() || {};
               console.debug('Video Width: ' + videoSettings.width);
               console.debug('Video Height: ' + videoSettings.height);
               this.currentWebcamSettings = videoSettings;
            }


         }
         catch (e){
            if(e.name === 'NotReadableError'){
               alert('Could not start selected webcam. Make sure it is not in use by another program.');
            }
            else {
               if(window?.BVExtras?.isWeb){
                  alert('Could not start webcam. Please refresh the page and try again.');
               }
               else {
                  alert(
                     'Could not start webcam. Please restart and try again. ' +
                     'If problem persists, scan the QR code on the back of the kiosk.'
                  );
               }

               console.warn(e);
            }
            this?.customFunctions?.cantStartWebcam?.();
         }
      }
   }

   stopWebcam(){
      this.domElement.srcObject.getTracks().forEach(track => track.stop());
   }

   unpauseWebcam(){
      if(this.domElement.paused){
         this.domElement.play();
      }
   }

   drawToCanvasElements(element){
      let {
         sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight,
         rotateXOffset, rotateYOffset
      } = this.webcamCanvasDrawOptions;
      for(let canvas of this.canvasElements) {

         let ctx = canvas.getContext('2d');
         ctx.imageSmoothingEnabled = false;
         if(this.cameraRotation){

            ctx.save();

            ctx.translate(rotateXOffset, rotateYOffset);
            ctx.rotate(this.cameraRotation);

            ctx.drawImage(
               element,
               sx, sy, sWidth, sHeight,
               dx, dy, dWidth, dHeight
            );

            ctx.restore();
         }
         else {
            ctx.drawImage(
               element,
               sx, sy, sWidth, sHeight,
               dx, dy, dWidth, dHeight
            );
         }
      }
   }

   drawToIntermediateCanvas(element){
      let {sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight} = this.webcamCanvasDrawOptions;
      let ctx = this.intermediateCanvas.getContext('2d');
      ctx.drawImage(
         element,
         sx, sy, sWidth, sHeight,
         dx, dy, dWidth, dHeight
      );
   }

   async setupLensIntegration(lensGroupId, lensId, lensUseBackCamera, lensIsPushToWeb, apiToken){
      let lensIntegration = new LensIntegration(
         lensGroupId, lensId, lensUseBackCamera, lensIsPushToWeb, this, apiToken
      );
      await lensIntegration.init(this.domElement);
      this.imageProcessor.addPipelineStep(lensIntegration);
   }

   async setupXr8Integration(){
      let xr8Integration = new Xr8Integration(this);
      await xr8Integration.init(this.domElement);
      this.imageProcessor.addPipelineStep(xr8Integration);
   }

   async setupSelfieSegmentation(){
      let selfieSegmentation = new SelfieSegmentationStage();
      await selfieSegmentation.init();
      this.imageProcessor.addPipelineStep(selfieSegmentation);
   }

   async setupPosenet(){
      let posenetSegmentation = new PosenetSegmentation();
      await posenetSegmentation.init();
      this.imageProcessor.addPipelineStep(posenetSegmentation);
   }

   async setupBodySegmentation(){
      let bodySegmentation = new BodySegmentation();
      await bodySegmentation.init();
      this.imageProcessor.addPipelineStep(bodySegmentation);
   }

   setupColorBalance(){
      let colorBalance = new ColorLevelSet();
      colorBalance.init();
      this.imageProcessor.addPipelineStep(colorBalance);
   }

   addCanvasElement(element){
      this.canvasElements.push(element);
      this.imageProcessor.setCanvasElements(this.canvasElements);
   }

   resetCanvasElements(){
      this.canvasElements = [];
      this.imageProcessor.setCanvasElements(this.canvasElements);
   }

   setCanvasElements(array){
      this.canvasElements = array;
      this.imageProcessor.setCanvasElements(this.canvasElements);
   }

   async runImageProcessor(image){
      await this.imageProcessor.run(image);
   }

   setSourcesInitialized(val){
      this.sourcesInitialized = val;
   }

   setIsRunning(val){
      this.isRunning = val;
   }

   /**
    *
    * @param src
    * @returns {Promise<boolean>} True if a segmentation type has been found (and therefore, stop looking for more)
    */
   async setupImageProcessorForSource(src){
      if(src.useLensIntegration){
         //await this.setupXr8Integration();

         await this.setupLensIntegration(
            src.lensGroupId,
            src.lensId,
            src.lensUseBackCamera,
            src.lensIsPushToWeb,
            src.lensApiToken
         );

      }
      else if(src.useXr8Integration){
         await this.setupXr8Integration();
      }
      else if(src.isForeground && src.segmentationType === 'Selfie'){
         await this.setupSelfieSegmentation();
         let tmp = document.createElement('canvas');
         tmp.width = 1920;
         tmp.height = 1080;

         await this.runImageProcessor(tmp);
         return true;
      }
      else if(src.isForeground){
         await this.setupBodySegmentation();

         let tmp = document.createElement('canvas');
         tmp.width = 1920;
         tmp.height = 1080;

         await this.runImageProcessor(tmp);
         return true;
      }
   }

   async onExperienceStop(){
      await this.imageProcessor.onExperienceStop();
   }

   async onExperienceStart(){
      await this.imageProcessor.onExperienceStart();
   }

   async updateVideoTrackConstraints(){
      let constraints = this.webcamConstraints;
      /*
      let constraints = {
         exposureTime: [
            {
               timeOfDay: '13:30',
               value: 200,
            },
            {
               timeOfDay: '17:00',
               value: 0,
            }
         ]
      };

       */

      let videoTrack = this.domElement.srcObject?.getVideoTracks?.()[0];
      if(videoTrack) {
         let videoSettings = videoTrack.getSettings?.() || {};
         console.debug('Video Width: ' + videoSettings.width);
         console.debug('Video Height: ' + videoSettings.height);
         console.log(videoSettings);
         let capabilities = {};
         if(videoTrack.getCapabilities){
            capabilities = videoTrack.getCapabilities();
         }
         console.debug(capabilities);
         let currentConstraints = videoTrack.getConstraints();
         console.debug(currentConstraints);
         let constraintType = 'exposureTime';
         if(constraints[constraintType]?.length > 0){
            let valueToApply = 0;

            let linearInterpolate = (currentTimeInMinutes, timeBefore, timeAfter) =>{
               let intervalTimeDiff = timeAfter.timeInMinutes - timeBefore.timeInMinutes;
               let currentTimeDiff = currentTimeInMinutes - timeBefore.timeInMinutes;
               let exposureDiff = timeAfter.value - timeBefore.value;
               let currentTimePercentage = currentTimeDiff / intervalTimeDiff;
               valueToApply = timeBefore.value + (currentTimePercentage * exposureDiff);
            };

            if(constraints[constraintType].length === 1){
               valueToApply = constraints[constraintType][0].value;

            }
            else if(constraints[constraintType].length > 1){
               let exposureTime = constraints[constraintType];
               exposureTime = exposureTime.map(x => {
                  let tokenizedTime = x.timeOfDay.split(':');
                  x.timeInMinutes = parseInt(tokenizedTime[0]) * 60 + parseInt(tokenizedTime[1]);
                  return x;
               }).sort((a,b) => a.timeInMinutes - b.timeInMinutes);
               let currentTime = new Date();
               let currentTimeInMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();
               if(currentTimeInMinutes < exposureTime[0].timeInMinutes){
                  let timeBefore = {
                     timeInMinutes: exposureTime[exposureTime.length - 1].timeInMinutes - (24 * 60),
                     value: exposureTime[exposureTime.length - 1].value
                  };

                  linearInterpolate(currentTimeInMinutes, timeBefore, exposureTime[0]);

               }
               else if(currentTimeInMinutes > exposureTime[exposureTime.length - 1].timeInMinutes){
                  let timeAfter = {
                     timeInMinutes: exposureTime[0].timeInMinutes + (24 * 60),
                     value: exposureTime[0].value
                  };

                  linearInterpolate(currentTimeInMinutes, exposureTime[exposureTime.length - 1], timeAfter);
               }
               else {
                  let timeBefore = exposureTime[0];
                  let timeAfter = exposureTime[exposureTime.length - 1];

                  for(let point of exposureTime){
                     if(point.timeInMinutes < currentTimeInMinutes && point.timeInMinutes > timeBefore.timeInMinutes){
                        timeBefore = point;
                     }
                     if(point.timeInMinutes > currentTimeInMinutes && point.timeInMinutes < timeAfter.timeInMinutes){
                        timeAfter = point;
                     }
                  }

                  linearInterpolate(currentTimeInMinutes, timeBefore, timeAfter);

               }
            }

            if(capabilities.exposureTime) {
               if(valueToApply > capabilities.exposureTime.max) {
                  valueToApply = capabilities.exposureTime.max;
               } else if (valueToApply < capabilities.exposureTime.min) {
                  valueToApply = capabilities.exposureTime.min;
               }
               await videoTrack.applyConstraints({advanced: [{exposureMode: 'manual'}]});
               await videoTrack.applyConstraints({advanced: [{exposureTime: valueToApply}]});
            }
         }
      }
   }

   createCameraRotationControl(){
      let box = document.createElement('input');
      box.setAttribute('id', 'cameraRotationControl');
      box.setAttribute('type', 'number');
      document.body.appendChild(box);
      box.addEventListener('change', (e) => {
         this.setCameraRotation(e.target.value);
      });
   }

   setCameraRotation(degrees){
      degrees = parseInt(degrees);
      if(degrees < 0){
         degrees = degrees + 360;
      }
      this.cameraRotation = degrees * Math.PI / 180;

      this.webcamCanvasDrawOptions.cameraRotation = this.cameraRotation;

      this.calculateWebcamCanvasDrawOptions(
         this.webcamCanvasDrawOptions.width, this.webcamCanvasDrawOptions.height,
         this.webcamCanvasDrawOptions.captureImageWidth, this.webcamCanvasDrawOptions.captureImageHeight
      );
   }

   applyRotationLUT(angle, dx, dy, width, height){
      let aspectRatio = width/height;
      angle = Math.round(angle  * 180 / Math.PI);
      console.debug('applying rotation LUT for angle: ' + angle);
      if(rotationLUT[angle]){
         let [scale, offset] = rotationLUT[angle];
         dx -= offset;
         dy -= offset / aspectRatio;
         width *= scale;
         height *= scale;
      }
      return [dx,dy, width, height];
   }
}

let rotationLUT = {
   0: [1, 0],
   1: [1.03, 11],
   2: [1.05, 22],
   3: [1.08, 33],
   4: [1.10, 46],
   5: [1.13, 58],
   6: [1.15, 73],
   7: [1.17, 85],
   8: [1.20, 98],
   9: [1.21, 113],
   85: [1.15, 165],
   86: [1.12, 135],
   87: [1.09, 100],
   88: [1.06, 65],
   89: [1.03, 32],
   90: [1, 0],
   91: [1.04, 60],
   92: [1.08, 120],
   93: [1.12, 180],
   94: [1.16, 240],
   95: [1.20, 300],
   265: [1.15, 165],
   266: [1.12, 135],
   267: [1.09, 100],
   268: [1.06, 65],
   269: [1.03, 32],
   270: [1, 0],
   271: [1.04, 60],
   272: [1.09, 120],
   273: [1.12, 178],
   274: [1.16, 240],
   275: [1.20, 300],
   355: [1.13, 155],
   356: [1.11, 125],
   357: [1.09, 95],
   358: [1.06, 65],
   359: [1.04, 35]

};

