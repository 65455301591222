import React, {useState, useEffect} from 'react';
import * as AjaxFunctions from '../editorAjaxFunctions.js';
import { Chart} from 'chart.js';
import {Button, Card, CardContent, Checkbox, CircularProgress, Divider, Grid, InputLabel, FormControl, FormControlLabel,
   ListItemText, MenuItem, OutlinedInput, Paper, Select, Stack, Typography, Tabs,Tab } from '@mui/material';
import {DateRange} from './dateRange.jsx';
import * as utils from '../utils.js';
import {idElementOptions} from '../../shared/dataTypes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-0ee4668a11/icons';

const types = {theme:'Theme', kiosk:'Kiosk'};
const GraphSectionType = {Daily:0, Hourly:1, Custom: 2};
const graphColors = [
   'rgba(38, 70, 83, 1)',
   'rgba(42, 157, 143, 1)',
   'rgba(233, 196, 106, 1)',
   'rgba(231, 111, 81, 1)',
   'rgba(244, 162, 97, 1)',
   'rgba(72, 63, 110, 1)',
   'rgba(126, 113, 128, 1)',
   'rgba(252, 77, 75, 1)',
   'rgba(23, 57, 67, 1)',
   'rgba(149, 97, 96, 1)',
   'rgba(64, 88, 81, 1)',
   'rgba(156, 155, 122, 1)',
   'rgba(244, 202, 90, 1)',
   'rgba(246, 213, 148, 1)',
   'rgba(247, 81, 42, 1)'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
};

const MIN_DATE = '2020-01-01';
const MAX_DIVISION = 100; // maximum graph division on x Axis

function downloadRowsAsCSV(rows, startDate, endDate, optionName, dataType){
   let content = rows.map(e => e.join(',')).join('\n');
   let csvContent = 'data:text/csv;charset=utf-8,' + content;

   let encodedUri = encodeURI(csvContent);
   let link = document.createElement('a');
   let dateRange = startDate === endDate?
      `${startDate}`:
      `${startDate}-${endDate}`;
   let fileName = `${optionName}-${dataType}-${dateRange}`;
   link.setAttribute('href', encodedUri);
   link.setAttribute('download', fileName);
   document.body.appendChild(link);

   link.click();
}

export class MediaDeliveryGraph extends React.Component {
   constructor (props) {
      super(props);
      let kioskList = this.props.kioskList.slice();
      if(props.displayWebAsKiosk){
         let webKioskList = this.props.scriptList.filter(x => x.isWeb).map(x => {
            return {
               idKiosk: `web_${x.idScript}`, nameByBv: `Web ${x.nickname}`, nickname: `Web: ${x.nickname}`
            };
         });
         kioskList = kioskList.concat(webKioskList);
      }

      this.state = {
         type: types.kiosk,
         kiosk:'Multiple',
         theme:'Multiple',
         themeNames:[],
         kioskNames:[],
         startDate:'',
         endDate:'',
         message:[],
         initialStartDate:'',
         initialEndDate:'',
         validSessions:{},
         snapshotSessions:{},
         loading:false,
         onlyShowLive: false,
         currentTimezone:'',
         scriptCheckAll:true,
         kioskCheckAll:true,
         curTab:0,
         kioskList
      };

      this.sessions = {};
      this.graphSectionType = GraphSectionType.Daily;
      this.safeDate = new Date('2023-01-07'); // date we assume that everything is updated. yyyy-mm-dd
      this.statsQuery;
   }

   async componentDidMount() {
      let ctxChart = document.getElementById('statsChartCanvas');

      let data = {
         labels: [],// array of strings
         datasets: [{
            label: '# of Deliveries',
            data: [],
            borderWidth: 0.4,
         }]
      };

      let options =  {
         scales: {
            yAxes: [{
               ticks: {
                  beginAtZero: true
               }
            }]
         }
      };

      this.chart =  new Chart(ctxChart,{
         type:'line',
         data,
         options
      });

      let currentDate = new Date();// est time with timezone
      let weekAgoDate = new Date();// est time with timezone

      if(!this.props.initialDaysRange)
         weekAgoDate.setMonth(currentDate.getMonth() - 1);
      else
         weekAgoDate.setDate(currentDate.getDate() - this.props.initialDaysRange);

      let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let initialEndDate = convertTZ(currentDate, currentTimezone); // est
      let initialStartDate = convertTZ(weekAgoDate, currentTimezone); // est
      let themeNames = this.props.scriptList.map(x => x.nickname);
      let kioskNames = this.state.kioskList.map(x => x.idKiosk);
      this.graphSectionType = GraphSectionType.Daily;
      this.setState({initialStartDate,
         initialEndDate,
         startDate: initialStartDate,
         endDate: initialEndDate,
         currentTimezone,
         themeNames,
         kioskNames
      },
      ()=>{
         this.updateGraph(false);
         this.updateSnapshot();
      });
   }

   setStartDate(value) {
      this.setState({startDate: value},()=>{this.updateGraph();});
   }
   setEndDate(value) {
      this.setState({endDate: value},()=>{this.updateGraph();});
   }

   selectType(type) {
      this.setState({type},()=>{this.updateGraph();});
   }

   selectKiosk(kiosk) {
      this.setState({kiosk},()=>{this.updateGraph();});
   }
   selectKioskNames(kioskNames) {
      this.setState({kioskNames},()=>{this.updateGraph();});
   }

   selectScript(theme) {
      this.setState({theme},()=>{this.updateGraph();});
   }
   selectScriptNames(themeNames) {
      this.setState({themeNames},()=>{this.updateGraph();});
   }

   checkOnlyLive() {
      this.setState({onlyShowLive: !this.state.onlyShowLive},()=>{this.updateGraph();});
   }

   async getSessions(startDate, endDate) {
      let startDateString = `${startDate} 00:00:00`; // est
      let endDateString = `${endDate} 23:59:59`; // est
      let {theme, kiosk, currentTimezone, onlyShowLive} = this.state;
      let themeNames = JSON.stringify(this.state.themeNames);
      let kioskNames = JSON.stringify(this.state.kioskNames);
      let idUser = window.localStorage.getItem('idUser');
      let sessions = {};
      let optionName;

      if(this.state.type === types.kiosk){
         if( kiosk === 'Multiple') {

            optionName = 'multipleKiosks';

            if(this.state.kioskNames.length === 0 )
               return {sessions: [], optionName};

            sessions = await AjaxFunctions.getMultipleSessionsByKiosks(
               startDateString,
               endDateString,
               idUser,
               currentTimezone,
               onlyShowLive,
               kioskNames,
            );
         }
         else if(kiosk) {
            optionName = this.state.kioskList.find(x =>x.idKiosk === kiosk)?.nickname || 'Kiosk';

            sessions = await AjaxFunctions.getSessionsWithIdKiosk(
               kiosk,
               startDateString,
               endDateString,
               idUser,
               currentTimezone,
               onlyShowLive
            );
         }
      }
      else {
         if(theme === 'Multiple') {
            optionName = 'multipleScripts';

            if(this.state.themeNames.length === 0 )
               return;

            sessions = await AjaxFunctions.getMultipleSessionsByScripts(
               startDateString,
               endDateString,
               idUser,
               currentTimezone,
               onlyShowLive,
               themeNames
            );
         }
         else if(theme) {

            let idScript = this.props.scriptList.find(x => x.nickname === theme).idScript;

            sessions = await AjaxFunctions.getSessionsWithIdScript(
               idScript,
               startDateString,
               endDateString,
               currentTimezone,
               onlyShowLive
            );
            optionName = theme;
         }
      }

      return {sessions, optionName};
   }

   //sessionGroups is the sessions grouped by script/kiosk, where the script/kiosk ids are the keys
   setGraphSectionType(dateStart, dateEnd59, dateEnd0, sessionGroups) {
      let difference = dateEnd0 - dateStart;
      let oneDayMillisec = 86400000;
      let maxRangeMillisec =  oneDayMillisec * MAX_DIVISION;
      let graphSectionType;

      if(difference > maxRangeMillisec) {
         graphSectionType = GraphSectionType.Custom;
      }
      else if(difference <= maxRangeMillisec && difference > oneDayMillisec ) {
         graphSectionType = GraphSectionType.Daily;
      }
      else if( difference <= oneDayMillisec) {
         graphSectionType = GraphSectionType.Hourly;
      }

      // if difference < 86400000
      // do it hourly

      let datasetPrefab = {};
      let dataset = {};
      let idScriptsInDeliveries = {};

      // Sectioning Graph checkpoints
      if(graphSectionType === GraphSectionType.Daily) {

         //By Date
         for(let curTime = dateStart; curTime < dateEnd59; curTime.setDate(curTime.getDate() + 1)){
            datasetPrefab[new Date(curTime)] = 0;
         }

         for(let id in sessionGroups) {

            let sessionsInGroup = sessionGroups[id];
            dataset[id] = {...datasetPrefab};

            if(this.state.type === types.kiosk)
               idScriptsInDeliveries[id] = [];

            for(let session of sessionsInGroup) {

               if((this.state.type === types.kiosk) && !idScriptsInDeliveries[id].includes(session.idScript))
                  idScriptsInDeliveries[id].push(session.idScript);

               for(let date in dataset[id]){
                  let sendTime = new Date(session.endTime); // est
                  if(sendTime.toLocaleDateString() === new Date(date).toLocaleDateString())
                     dataset[id][date] += 1;
                  /* from back when we were just showing deliveries
                  if(
                     sendTime.toLocaleDateString() === new Date(date).toLocaleDateString()
                     && (
                        data?.userVariables?.smsSent
                        || data?.userVariables?.emailSent
                        || data?.fromWeb
                     )
                  )
                     dataset[id][date] += 1;

                   */
               }
            }
         }
      }
      else if (graphSectionType === GraphSectionType.Hourly) {

         //By Hours
         let increment = difference / 24;
         let curTime = dateStart.getTime();
         let endTime = dateEnd0.getTime();

         while(curTime <= endTime) {
            let curTimeDate = new Date(curTime);
            datasetPrefab[`${curTimeDate}`] = 0;
            curTime += increment;
         }


         for(let id in sessionGroups) {
            // id can be idKiosk or idScript
            let sessionsInGroup = sessionGroups[id];
            dataset[id] = {...datasetPrefab};

            if(this.state.type === types.kiosk)
               idScriptsInDeliveries[id] = [];

            for(let index in sessionsInGroup) {
               let session = sessionsInGroup[index];
               let oldDate;

               if((this.state.type === types.kiosk) && !idScriptsInDeliveries[id].includes(session.idScript))
                  idScriptsInDeliveries[id].push(session.idScript);


               for(let date in dataset[id]){
                  let sendTime = new Date(session.endTime); // est

                  if(oldDate) {
                     //let data = JSON.parse(session.data);
                     if(
                        new Date(date).getTime() >= sendTime.getTime()
                        && new Date(oldDate).getTime() < sendTime.getTime()
                     )
                        dataset[id][date] += 1;
                     /*
                     if(
                        new Date(date).getTime() >= sendTime.getTime()
                        && new Date(oldDate).getTime() < sendTime.getTime()
                        && (
                           data?.userVariables?.smsSent
                           || data?.userVariables?.emailSent
                           || data?.fromWeb
                        )
                     )
                        dataset[id][date] += 1;

                      */
                  }
                  oldDate = date;
               }
            }
         }
      }
      else if(graphSectionType === GraphSectionType.Custom){
         // find what would be the increment in number of days
         let increment = 1; // start with 1 day apart
         while((difference / increment) / oneDayMillisec > MAX_DIVISION) {
            increment += 1; // then plus one until division smaller than max division
         }

         //By Week
         let curTime = dateStart;

         datasetPrefab[new Date(curTime)] = 0;

         while(curTime < dateEnd59) {

            curTime.setDate(curTime.getDate() + increment);

            if(curTime.getDate() >= dateEnd59.getDate() &&
               curTime.getMonth() === dateEnd59.getMonth() &&
               curTime.getFullYear() === dateEnd59.getFullYear()
            )
            {
               // if the date exceed end date. use the end date at 23:59:59
               datasetPrefab[new Date(dateEnd59)] = 0;
               break;
            }
            else
               datasetPrefab[new Date(curTime)] = 0;

         }


         for(let id in sessionGroups) {
            if(this.state.type === types.kiosk)
               idScriptsInDeliveries[id] = [];

            // id can be idKiosk or idScript
            let sessionsInGroup = sessionGroups[id];
            dataset[id] = {...datasetPrefab};

            for(let index in sessionsInGroup) {
               let session = sessionsInGroup[index];
               let oldDate;

               if((this.state.type === types.kiosk) && !idScriptsInDeliveries[id].includes(session.idScript))
                  idScriptsInDeliveries[id].push(session.idScript);


               for(let date in dataset[id]){
                  let sendTime = new Date(session.endTime); // est

                  if(oldDate)
                     if(
                        new Date(date).getTime() >= sendTime.getTime()
                        && new Date(oldDate).getTime() < sendTime.getTime()
                     )
                        dataset[id][date] += 1;
                  oldDate = date;
               }
            }
            delete dataset[id][Object.keys(dataset[id])[0]];
         }
      }
      return {datasetPrefab, dataset, idScriptsInDeliveries, graphSectionType};
   }

   prepareChartLabels (datasetPrefab) {

      let hourOption = {hour:'numeric', minute:'numeric'};
      let dateOption = {month: 'numeric',day: 'numeric',year: 'numeric'};
      let customStartDate= {month: 'numeric', day: 'numeric'};
      let customEndDate= {month: 'numeric', day: 'numeric', year: 'numeric', hour:'numeric', minute:'numeric'};

      // Creating Chart labels

      let dates = Object.keys(datasetPrefab);

      switch (this.graphSectionType) {

         case GraphSectionType.Daily:
            this.chart.data.labels = dates.map(x => new Date(x).toLocaleString({},dateOption));
            break;

         case GraphSectionType.Custom:
            // label should be a range like 23/3 - 27/3/2023 12am
            this.chart.data.labels = dates.map((x, index) => {
               if(index !== 0)
                  return `${new Date(dates[index-1]).toLocaleString({},customStartDate)} - ${new Date(x).toLocaleString({},customEndDate)}`;
               else return null;
            }).filter(x=> x !== null);
            break;

         case GraphSectionType.Hourly:
            this.chart.data.labels = dates.map(x => new Date(x).toLocaleString({},hourOption));
            break;
      }
   }
   getValidData (dataset, idScriptsInDeliveries, deliveries, sessions) {
      let validSessions = {};
      let graphIndex = 0;

      // Counting deliveries and session options
      for(let id in dataset) {

         let label;
         let selectionsMaps = {};

         if(this.state.type === types.theme){
            let match = this.props.scriptList.find(x => x.idScript === id);

            if(match){
               label = match.nickname;
               let parsed = JSON.parse(match.selectionsMap) || [];
               for(let item of parsed){
                  for(let value of item.values){
                     selectionsMaps[value] = item.playerName;
                  }
               }
            }
         }
         else {

            let match = this.state.kioskList.find(x => x.idKiosk === id);

            if(match){
               label = this.props.isAdminPage? match.nameByBv : match.nickname;

               let scriptObjs = this.props.scriptList.filter(x => idScriptsInDeliveries[id].includes(x.idScript));
               for(let scriptObj of scriptObjs){
                  let parsed = JSON.parse(scriptObj.selectionsMap) || [];
                  for(let item of parsed){
                     for(let value of item.values){
                        selectionsMaps[value] = item.playerName;
                     }
                  }
               }
               //selectionsMaps = scriptObjs.map(scriptObj => JSON.parse(scriptObj.selectionsMap));
            }
         }
         if(!label)
            continue;

         // get session count and player selected
         if(sessions[id]){

            validSessions[id] = {};
            validSessions[id]['options'] = {}; // {video1:{Lamar: 42, Tucker :12}, video2: {Lamar:21, Tucker :12}}
            validSessions[id]['optIn'] = 0;
            validSessions[id]['abandonment'] = 0;
            validSessions[id]['emailCount'] = 0;
            validSessions[id]['phoneCount'] = 0;
            validSessions[id]['count'] = 0;

            if(this.state.type === types.theme){
               let match = this.props.scriptList.find(x => x.idScript === id);

               if(match){
                  validSessions[id].nameByBv = match.nameByBv;
                  validSessions[id].nickname = match.nickname;
               }
            }
            else if(this.state.type === types.kiosk) {
               let match = this.state.kioskList.find(x => x.idKiosk === id);

               if (match) {
                  validSessions[id].nameByBv = match.nameByBv;
                  validSessions[id].nickname = match.nickname;
               }
            }


            for(let session of sessions[id]) {
               let result = JSON.parse(session.data);

               validSessions[id]['count']++;

               if(result.userVariables?.smsSent){
                  validSessions[id]['phoneCount']++;
                  //validSessions[id]['count']++;
               }
               if(result.userVariables?.emailSent){
                  validSessions[id]['emailCount']++;
                  //validSessions[id]['count']++;
               }
               if(result.fromWeb){
                  //validSessions[id]['count']++;
               }

               if(Object.keys(result.userVariables).length === 0) {
                  // when userVariables is empty
                  validSessions[id]['abandonment'] += 1;
                  continue;
               }

               if(new Date(session.endTime).getTime() > new Date(this.safeDate).getTime())
               {
                  if(
                     !result.userVariables.emailSent && !result.userVariables.smsSent && !result.fromWeb
                     && !result.userVariables.qrSent
                  ){
                     // session abandoned
                     validSessions[id]['abandonment'] += 1;
                     continue;
                  }
               }

               let optIn = result.userVariables.optIn || result.userVariables.optin;

               if(optIn === null || optIn === undefined)
                  optIn = result.userVariables.smsAccept ||
                           result.userVariables.emailAccept ||
                           result.userVariables.acceptPhone ||
                           result.userVariables.acceptEmail;

               if(optIn)
                  validSessions[id]['optIn'] += 1;

               for(let idElement of idElementOptions) {

                  let playerNames = []; // make sure it doesn't repeat

                  if(result.userVariables[idElement]) {
                     let optionType;
                     if(idElement.includes('video'))
                        optionType = 'Options';
                     else if(idElement.includes('cardBorder'))
                        optionType = 'Frame';
                     else if(idElement === 'lenseId')
                        optionType = 'Lens Id';
                     else {
                        optionType = idElement;
                     }

                     let playerName = selectionsMaps?.[result.userVariables[idElement]];
                     //if the video has a random number appended, remove that number and see if that has a result
                     if(!playerName){
                        let valueNoNumber = result.userVariables[idElement]?.replace(/\d+$/, '');
                        playerName = selectionsMaps?.[valueNoNumber];
                     }
                     if(!playerName){
                        playerName = result.userVariables[idElement];
                     }
                     if(playerName){
                        if(!playerNames.includes(playerName))
                           playerNames.push(playerName);
                     }

                     if(playerNames.length <= 0)
                        continue;



                     for(let playerName of playerNames) {

                        if(!validSessions[id]['options'][optionType])
                           validSessions[id]['options'][optionType] = {};

                        if(!validSessions[id]['options'][optionType][playerName])
                           validSessions[id]['options'][optionType][playerName] = 0;

                        validSessions[id]['options'][optionType][playerName] += 1;
                     }

                  }
               }
            }
         }

         let colorIndex = graphIndex % graphColors.length;
         let data = Object.values(dataset[id]);

         validSessions[id].label = label;
         validSessions[id].data = data;
         validSessions[id].color = graphColors[colorIndex];
         graphIndex++;
      }
      return {validSessions};
   }

   prepareChartData(validSessions) {

      this.chart.data.datasets = [];

      for(let id in validSessions)
      {
         this.chart.data.datasets.push(
            {
               label: validSessions[id].label,
               data: validSessions[id].data,
               lineTension:0,
               fill: false,
               backgroundColor: 'rgba(1,1,1,0)',
               borderColor: validSessions[id].color,
            }
         );
      }
   }

   async updateGraph(delay = true) {

      if(this.notValid())
         return;

      this.setState({loading:true});

      if(this.statsQuery) {
         clearTimeout(this.statsQuery);
         this.statsQuery = null;
      }

      this.statsQuery = setTimeout( async() => {

         let dateStart = new Date(`${this.state.startDate} 00:00:00`);
         let dateEnd59 = new Date(`${this.state.endDate} 23:59:59`);
         let dateEnd0 = new Date(`${this.state.endDate} 24:00:00`);

         let {sessions, optionName} = await this.getSessions(this.state.startDate, this.state.endDate);
         this.sessions = sessions;
         this.optionName = optionName;

         // 3.11e+9 - 36 days
         // 86400000 - 24 hours, 3600000 - 1 hour
         let {
            datasetPrefab, dataset, idScriptsInDeliveries, graphSectionType
         } = this.setGraphSectionType(dateStart, dateEnd59, dateEnd0, sessions);
         this.graphSectionType = graphSectionType;

         // Preparing Chart data
         this.prepareChartLabels(datasetPrefab);
         let {validSessions} = this.getValidData(dataset, idScriptsInDeliveries, sessions, sessions );
         this.prepareChartData(validSessions);


         this.chart.update();
         this.setState({loading:false, validSessions});


         this.statsQuery = null;
      }, delay ? 3000 : 50);
   }

   async updateSnapshot()
   {
      this.setState({loading:true});
      let dateStart = new Date(`${this.state.endDate} 00:00:00`);
      let dateEnd59 = new Date(`${this.state.endDate} 23:59:59`);
      let dateEnd0 = new Date(`${this.state.endDate} 24:00:00`);

      let {sessions} = await this.getSessions(this.state.endDate, this.state.endDate);
      let { dataset, idScriptsInDeliveries } = this.setGraphSectionType(dateStart, dateEnd59, dateEnd0, sessions);
      let {validSessions} = this.getValidData(dataset, idScriptsInDeliveries, sessions, sessions );

      this.setState({loading:false, snapshotSessions: validSessions});
   }

   downloadCSV(sessions) {
      let rows = [['sendTime', 'kioskName', 'selection']];
      for(let sessionGroupId in sessions) {
         for(let session of sessions[sessionGroupId])
         {
            let scriptObj = this.props.scriptList.find(script => script.idScript === session.idScript);

            if(!scriptObj) // script isn't live
               continue;

            let selections = this.lookUpDeliveredSession(scriptObj, session);

            if(selections === 'null')
               continue;

            let endTime = session.endTime;
            let formatSendTime = new Date(endTime).toLocaleString().replace(',','');

            let kioskName = session.kioskName || session.boothName;

            rows.push([formatSendTime, kioskName, selections ]);
         }
      }

      downloadRowsAsCSV(rows, this.state.startDate, this.state.endDate, this.optionName, 'deliveries');
   }

   lookUpName(selectionsMap, videoUrl)
   {
      let playerName;

      if(!selectionsMap)
         return playerName;

      for(let selections of selectionsMap) {
         for(let value of selections.values){
            if(videoUrl && videoUrl.includes(value)) {
               playerName = selections.playerName;
               return playerName;
            }
         }
      }

      return playerName;
   }

   lookUpDeliveredSession(scriptObj, session)
   {
      let selectionsMap = JSON.parse(scriptObj.selectionsMap);
      let toReturn = [];

      let result = JSON.parse(session.data);
      if(!result.userVariables.emailSent && !result.userVariables.smsSent && !result.fromWeb)
      {
         return 'null';
      }
      if(result.userVariables.video1) {
         let playerName = this.lookUpName(selectionsMap, result.userVariables.video1);
         toReturn.push(playerName);
      }
      if(result.userVariables.video2) {
         let playerName = this.lookUpName(selectionsMap, result.userVariables.video2);
         if(!toReturn.includes(playerName))
            toReturn.push(playerName);
      }
      if(result.userVariables.cardBorder) {
         let playerName = this.lookUpName(selectionsMap, result.userVariables.cardBorder);
         if(!toReturn.includes(playerName))
            toReturn.push(playerName);
      }

      return toReturn.join('+');
   }

   downloadSessions(sessions) {
      let rows = [['idSessionInfo', 'boothName', 'route', 'idScript', 'idKiosk', 'startTime', 'endTime', 'data']];
      for (let session of sessions) {
         for (let sess of session) {
            let toAdd = Object.values(sess).map((value, index) => {
               if(index === 5 || index === 6)
                  return new Date(value).toLocaleString().replace(',','');
               if(typeof value === 'string')
                  return value;
               else
                  return JSON.stringify(value);
            });

            rows.push(toAdd);
         }
      }


      downloadRowsAsCSV(rows, this.state.startDate, this.state.endDate, this.optionName, 'sessions');
   }

   notValid() {
      // return true if
      // start date and end date not valid
      // script id or script kiosk is unselected
      let dateEmpty = this.state.startDate === '' || this.state.endDate === '';
      let tooSoon =
         new Date(this.state.startDate) < new Date(MIN_DATE)
         || new Date(this.state.endDate) < new Date(MIN_DATE);
      let tooLate = new Date(this.state.startDate) > new Date() || new Date(this.state.endDate) > new Date();
      let dateRangeNotValid = new Date(this.state.startDate) > new Date(this.state.endDate);
      let idEmpty = (this.props.scriptList != null && this.state.type === 'Theme' && this.state.themeNames === '') ||
         (this.state.kioskList != null && this.state.type === 'Kiosk' && this.state.kiosk === '');

      let message = [];
      if(dateEmpty)
         message.push('Date cannot be Empty.');
      if(dateRangeNotValid)
         message.push('Date Range not Valid.');
      if(idEmpty)
         message.push('Script or Kiosk id not valid.');
      if(tooSoon)
         message.push('Date cannot be before 01/01/2020.');
      if(tooLate)
         message.push('Date cannot be after current date.');

      this.setState({message});

      return dateEmpty || dateRangeNotValid || idEmpty || tooSoon || tooLate;
   }

   checkAllScript() {
      this.setState({scriptCheckAll: !this.state.scriptCheckAll}, () => {
         if(this.state.scriptCheckAll)
         {
            let themeNames = this.props.scriptList.map(x => x.nickname);
            this.setState({
               themeNames,
            }, ()=>{
               this.updateGraph();
            });
         }
         else {
            this.setState({
               themeNames:[],
            }, ()=>{
               this.updateGraph();
            });
         }
      });

   }

   checkAllKiosk() {
      this.setState({kioskCheckAll: !this.state.kioskCheckAll}, () => {
         if(this.state.kioskCheckAll)
         {
            let kioskNames = this.state.kioskList.map(x => x.idKiosk);
            this.setState({
               kioskNames,
            }, ()=>{
               this.updateGraph();
            });
         }
         else {
            this.setState({
               kioskNames:[],
            }, ()=>{
               this.updateGraph();
            });
         }
      });

   }

   setTab(tabIndex)
   {
      this.setState({curTab:tabIndex});
   }

   render() {
      return (
         <div id='statsPage'>
            {
               this.props.isAdminPage &&
               <Tabs value={this.state.curTab}>
                  <Tab onClick={() => this.setTab(0) } label={'Full Stats'}/>
                  <Tab onClick={() => this.setTab(1) } label={'Snapshot'}/>
               </Tabs>
            }
            <Stack direction={'column'} spacing={5}>
               <Paper className={'content-box'} elevation={3} style={{display: this.state.curTab !== 0 ? 'none':''}}>
                  <h4 style={{display:'inline'}}>PHOTOBOOTH ANALYTICS</h4>
                  <Stack id={'graph-controls'} direction={window.BV?.isMobile? 'column' : 'row'} justifyContent={'space-between'} spacing={window.BV?.isMobile? 0 : 2}>
                     <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} spacing={window.BV?.isMobile? 0 : 1}>
                        {
                           this.props.scriptList != null &&
                           this.state.kioskList != null &&
                           <Select
                              value={this.state.type}
                              sx={{minWidth:120, backgroundColor:'#1976d3', color:'white'}}
                              onChange={(e) =>
                                 this.selectType(e.target.value)
                              }
                           >
                              {
                                 Object.values(types).map(x => {
                                    return (
                                       <MenuItem key={x} value={x}>{x}</MenuItem>
                                    );
                                 })
                              }
                           </Select>
                        }
                        {
                           this.props.scriptList != null &&
                           this.state.type === 'Theme' &&
                           <Select
                              value={this.state.theme}
                              sx={{minWidth:120, backgroundColor:'#1976d3', color:'white'}}
                              onChange={(e) =>
                                 this.selectScript(e.target.value)
                              }
                           >
                              <MenuItem key={'script-all'} value={'Multiple'}>Multiple</MenuItem>
                              {
                                 this.props.scriptList.map((x,index)=> {
                                    return (
                                       <MenuItem key={'script-'+index} value={x.nickname}>{x.nickname}</MenuItem>
                                    );
                                 })
                              }
                           </Select>
                        }
                        {
                           this.props.scriptList != null &&
                           this.state.type === 'Theme' &&
                           this.state.theme === 'Multiple' &&
                           this.props.isAdminPage &&
                           <FormControl sx={{ width:120 }}>
                              <Select
                                 multiple
                                 sx={{backgroundColor:'#1976d3', color:'white'}}
                                 value={this.state.themeNames}
                                 onChange={(e) => this.selectScriptNames(e.target.value)}
                                 renderValue={(selected) => selected.filter(x=>x && x.length > 0).join(', ')}
                                 MenuProps={MenuProps}
                              >
                                 <MenuItem key={'script-checkall'}>
                                    <Checkbox checked={this.state.scriptCheckAll} onClick={() => this.checkAllScript()}/>
                                    <ListItemText primary={'All'} onClick={() => this.checkAllScript()}/>
                                 </MenuItem>
                                 {this.props.scriptList.map((script, index) => (
                                    <MenuItem key={'script-'+index} value={script.nickname}>
                                       <Checkbox checked={this.state.themeNames.findIndex(x=> x === script.nickname) > -1} />
                                       <ListItemText primary={script.nickname} />
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>
                        }
                        {
                           this.state.kioskList != null &&
                           this.state.type === 'Kiosk' &&
                           <Select
                              value={this.state.kiosk}
                              sx={{minWidth:120, backgroundColor:'#1976d3', color:'white'}}
                              onChange={(e) =>
                                 this.selectKiosk(e.target.value)
                              }
                           >
                              <MenuItem key={'kiosk-all'} value={'Multiple'}>Multiple</MenuItem>
                              {
                                 this.state.kioskList.map(x => {
                                    return (
                                       <MenuItem key={'kiosk-'+x.idKiosk} value={x.idKiosk}>{this.props.isAdminPage?x.nameByBv : x.nickname}</MenuItem>
                                    );
                                 })
                              }
                           </Select>
                        }
                        {
                           this.state.kioskList != null &&
                           this.state.type === 'Kiosk' &&
                           this.state.kiosk === 'Multiple' &&
                           this.props.isAdminPage &&
                           <FormControl sx={{ width:120 }}>
                              <Select
                                 multiple
                                 sx={{backgroundColor:'#1976d3', color:'white'}}
                                 value={this.state.kioskNames}
                                 onChange={(e) => this.selectKioskNames(e.target.value)}
                                 renderValue={(selected) => {
                                    return this.state.kioskList.map((kiosk) => {
                                       if(selected.includes(kiosk.idKiosk))
                                          return kiosk.nameByBv;
                                    }).filter(x=>x && x.length > 0).join(', ');
                                 }}
                                 MenuProps={MenuProps}
                              >
                                 <MenuItem key={'kiosk-checkall'}>
                                    <Checkbox checked={this.state.kioskCheckAll} onClick={() => this.checkAllKiosk()}/>
                                    <ListItemText primary={'All'} onClick={() => this.checkAllKiosk()}/>
                                 </MenuItem>
                                 {this.state.kioskList.map((kiosk, index) => (
                                    <MenuItem key={'kiosk-'+index} value={kiosk.idKiosk}>
                                       <Checkbox checked={this.state.kioskNames.findIndex(x=> x === kiosk.idKiosk) > -1} />
                                       <ListItemText primary={kiosk.nameByBv} />
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>
                        }
                        {
                           this.props.isAdminPage &&
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={this.state.onlyShowLive}
                                    onChange={(e) =>
                                       this.checkOnlyLive()
                                    }
                                 />
                              }
                              label='Only Live'
                           />
                        }
                     </Stack>
                     <div id={window.BV?.isMobile? '' : 'date-range'}>
                        <DateRange
                           defaultStartDate={this.state.initialStartDate}
                           defaultEndDate={this.state.initialEndDate}
                           currentTimezone={this.state.currentTimezone}
                           minDate={MIN_DATE}
                           todayDate={utils.getTodayDate()}
                           setStartDate={(value)=> this.setStartDate(value)}
                           setEndDate={(value)=> this.setEndDate(value)}
                           onRefresh={()=>this.updateGraph()}/>
                     </div>
                  </Stack>

                  <div className='note'>
                     {this.state.message.map(x=>{
                        return (
                           <li>{x}</li>
                        );
                     })}
                  </div>
                  <Paper id='chartWrapper'>
                     <canvas id={'statsChartCanvas'} style={{height:window?.BV?.isMobile?'60vh':''}}> </canvas>
                     {
                        this.state.loading &&
                        <CircularProgress
                           className={'chart-loading-icon'}
                           size={'4rem'}
                        />
                     }
                  </Paper>
                  <div>
                     <Button variant='contained' onClick={()=>{this.downloadCSV(this.sessions);}}>
                        Download Datasheet
                     </Button>
                     {
                        this.props.isAdminPage &&
                        <Button
                           variant='contained'
                           onClick={()=>{ this.downloadSessions(Object.values(this.sessions));} }
                        >
                           Download Sessions
                        </Button>
                     }
                  </div>
               </Paper>
               <Paper className={'content-box'} elevation={3}>
                  <DataDetail
                     snapshot={this.state.curTab === 1}
                     type={this.state.type}
                     loading={this.state.loading}
                     sessions={this.state?.validSessions}
                     snapshotSessions={this.state?.snapshotSessions}
                     isAdminPage={this.props.isAdminPage}
                  />
               </Paper>
            </Stack>
         </div>
      );
   }
}

function OptionDetails(props){
   const [expanded, setExpanded] = useState(false);
   let isExpandedCssClass = expanded ? 'expanded' : 'collapsed';
   return (
      <div
         className={'option-details'}
         style={{display: props.snapshot ? 'none' : ''}}
      >
         <h5>Selections
            <FontAwesomeIcon
               className={'expand-collapse-button ' + isExpandedCssClass}
               icon={byPrefixAndName.fas['chevron-down']}
               onClick={() => setExpanded(!expanded)}
            />
         </h5>
         {
            Object.entries(props.sessions.options).map(entry => {
               return (
                  <SelectionCountsDisplay
                     key={`${props.id}-${entry[0]}`}
                     entry={entry}
                     id={props.id}
                     nickname={props.sessions.nickname}
                     cssClasses={'expand-collapse-surface ' + isExpandedCssClass}
                  />
               );
            })
         }
      </div>
   );
}

function DataDetail(props) {

   let sessions;

   sessions = props.snapshot ? props.snapshotSessions : props.sessions;

   let totalSessions = 0;
   let totalEmail = 0;
   let totalPhone = 0;


   for (let id in sessions) {
      totalSessions += sessions[id].count;
      totalPhone += sessions[id].phoneCount;
      totalEmail += sessions[id].emailCount;
   }

   useEffect(() => {
      sessions = props.snapshot ? props.snapshotSessions : props.sessions;

      totalSessions = 0;
      totalEmail = 0;
      totalPhone = 0;

      for (let id in sessions) {
         totalSessions += sessions[id].count;
         totalPhone += sessions[id].phoneCount;
         totalEmail += sessions[id].emailCount;
      }

   }, [props.snapshot, props.sessions]);

   return (
      <div>
         {
            !props.loading &&
            <div className='data-details'>
               <h5>DATA BREAKDOWN</h5>
               <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                     <Card sx={{marginBottom:'1em'}}>
                        <CardContent>
                           <div className={'stats-overview-line-item'}>
                              <span>Total Sessions</span>
                              <span>{totalSessions}</span>
                           </div>
                           <div className={'stats-overview-line-item'}>
                              <div>Total Phone Deliveries</div>
                              <div>{totalPhone}</div>
                           </div>
                           <div className={'stats-overview-line-item'}>
                              <div>Total Email Deliveries</div>
                              <div>{totalEmail}</div>
                           </div>
                        </CardContent>
                     </Card>
                  </Grid>
               </Grid>
               <Grid container spacing={2} className={'org-details'}>
                  {
                     Object.keys(sessions).map((id, i) => {

                        return (
                           <Grid key={`data-${id}`} item xs={12} md={4}>
                              <Card key={`data-detail-${i}`} className={'detail-item'}>
                                 <CardContent>
                                    <DataDetailItemHeader
                                       sessions={sessions[id]}
                                       isAdminPage={props.isAdminPage}
                                       type={props.type}
                                    />
                                    {
                                       Object.keys(props.sessions[id].options).length > 0 &&
                                       <React.Fragment>
                                          <Divider style={{display: props.snapshot ? 'none':''}}/>
                                          <OptionDetails
                                             sessions={props.sessions[id]}
                                             id={props.id}
                                             snapshot={props.snapshot}
                                          />
                                       </React.Fragment>
                                    }

                                 </CardContent>
                              </Card>
                           </Grid>
                        );
                     })
                  }
               </Grid>
            </div>
         }
         {
            props.loading && props.snapshot &&
            <CircularProgress
               className={'chart-loading-icon'}
               size={'4rem'}
            />
         }
      </div>
   );
}

function SelectionCountsDisplay(props){
   return (
      <div className={'SelectionCountsDisplay ' + props.cssClasses} >
         <h6>{props.entry[0]}</h6>
         {
            Object.entries(props.entry[1]).sort((a,b) =>{
               return b[1] - a[1];
            }).map(statsEntry => {
               return (
                  ( statsEntry[1] !== 0 && !isNaN(statsEntry[1]) ) &&
                  <div className={'stats-overview-line-item'}>
                     <span>{statsEntry[0]}</span>
                     <span>{statsEntry[1]}</span>
                  </div>
               );
            })
         }
      </div>
   );
}

function DataDetailItemHeader(props){
   return (
      <React.Fragment>
         <div className={'stats-overview-line-item'}>
            {
               props.type === types.kiosk &&
               <h4>{props.isAdminPage? props.sessions.nameByBv : props.sessions.nickname}</h4>
            }
            {
               props.type === types.theme &&
               <h4>{props.sessions.nickname}</h4>
            }
            <div className={'chip'} style={{backgroundColor:props.sessions.color}}> </div>
         </div>
         <div className={'stats-overview-line-item'}>
            <div>Total Sessions</div>
            <div>{props.sessions.count}</div>
         </div>
         <div className={'stats-overview-line-item'}>
            <div>Phone Deliveries</div>
            <div>{props.sessions.phoneCount}</div>
         </div>
         <div className={'stats-overview-line-item'}>
            <div>Email Deliveries</div>
            <div>{props.sessions.emailCount}</div>
         </div>
         {
            props.sessions.optIn > 0 &&
            <div className={'stats-overview-line-item'}>
               <div>Total Opt-in</div>
               <div>{props.sessions.optIn}</div>
            </div>
         }
         {
            props.sessions.optIn === 0 &&
            <div className={'stats-overview-line-item'} style={{color: 'transparent'}}>
               .
            </div>
         }

         {
            props.showAbandonmentStats &&
            <div className={'stats-overview-line-item'}>
               <div>Total Abandonment</div>
               <div>{props.sessions.abandonment}</div>
            </div>
         }
      </React.Fragment>
   );
}

function convertTZ(date, tzString) {
   if(typeof date === 'string')
      date = new Date(date);

   let offsetDate = date.toLocaleDateString('en-US', { timeZone: tzString}); // mm/dd/yyyy
   let splits = offsetDate.split('/');
   return splits[2] + '-' + splits[0].padStart(2, '0') + '-' + splits[1].padStart(2, '0');
   // return needs to be yyyy-mm-dd
}