import React, {useEffect, useState} from 'react';
import {NavigationButtons} from '../navigationButtons.jsx';
import {resolveUrl} from '../../utils.js';
import {Background} from '../background.jsx';
import {CustomElementsList} from '../customElementsList.jsx';
import {ReactProgressRing} from '../reactProgressRing.jsx';

let takingPicture = false;

export class ManualCaptureScreen extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         currentImage: null,
      };
   }

   start(){
      window.BV.photoBoothExperience.run().catch((e) => {
         console.warn(e);
      });
   }

   async runPhotoboothExperience(){
      return await new Promise(resolve => {
         if(window.BV.photoBoothExperience) {
            this.start();
            resolve();
         }
         else {
            window.setTimeout(() => {
               if(window.BV.photoBoothExperience) {
                  this.start();
                  resolve();
               }
            }, 1000);
         }
      });

   }

   async startVideoRecording(){
      await window.BV.photoBoothExperience.startRecording();
   }

   async stopVideoRecording(){
      await window.BV.photoBoothExperience.stopRecording();
   }

   componentDidMount() {
      this.runPhotoboothExperience();
      document.getElementById('bvPhotoBoothRoot').style.zIndex = '0';
   }

   componentWillUnmount(){
      window.BV.photoBoothExperience?.stop();
      document.getElementById('bvPhotoBoothRoot').style.zIndex = '3';
   }

   async takePicture(){
      if(takingPicture){
         return;
      }
      takingPicture = true;
      window.BV.photoBoothExperience.showLoadingOverlay();
      if(this.props.manualCaptureAdvanceScreenOnPicture){
         await window.BV.photoBoothExperience.captureScreenshot(0, true);
      }
      else {
         let image = await window.BV.photoBoothExperience.captureScreenshot(0, false);
         this.setState({image});
         await window.BV.app.setUserVariables({manualCapturedImage: image.src});
         window.BV.photoBoothExperience.finalizePicture(image.src, false);
         window?.BV?.onManualCapturePicture?.();
      }
      window.BV.photoBoothExperience.hideLoadingOverlay();
      takingPicture = false;

   }

   retakePicture(){
      this.setState({image: null});
      window.BV.app.setUserVariables({manualCapturedImage: null, rawWebcamSrc: null});
   }

   render() {
      return (
         <div className="manual-capture-screen">
            <div className={'manual-capture-screen-content'}>
               <CustomElementsList
                  userData={this.props.userData}
                  customElements={this.props.elements}
                  userVariables={this.props.userVariables}
               />
               <PreviewOverlay image={this.state.image} />
               <div id={'manualCaptureScreenButtons'}>
                  {
                     !this.state.image &&
                     <TakeButton {...this.props} onClick={() => this.takePicture()}/>
                  }
                  {
                     this.state.image &&
                     <RetakeButton {...this.props} onClick={() => this.retakePicture()}/>
                  }
                  {
                     !this.state.image &&
                     <MultiButton
                        takePicture={() => this.takePicture()}
                        startRecording={async () => await this.startVideoRecording()}
                        stopRecording={async () => await this.stopVideoRecording()}
                     />
                  }

               </div>
               <NavigationButtons
                  backButtonImageUrl={this.props.backButtonImageUrl}
                  backButtonText={this.props.backButtonText}
                  nextButtonImageUrl={this.state.image && this.props.nextButtonImageUrl}
                  nextButtonText={this.state.image && this.props.nextButtonText}
                  goToPreviousScreen={() => this.props.goToPreviousScreen()}
                  goToNextScreen={() => this.props.goToNextScreen()}
               />
            </div>
            <Background userData={this.props.userData} elements={this.props.backgroundElements}/>


         </div>
      );
   }
}

export function PreviewOverlay(props){
   if(!props.image){
      return null;
   }
   return (
      <React.Fragment>
         {
            props.image &&
            <div
               className={'manual-capture-screen-retake-background'}
            />
         }
         {
            props.image &&
            <img
               src={window.URL.createObjectURL(props.image.src)}
               className={'preview-image'}
            />
         }
      </React.Fragment>
   );
}

export function MultiButton(props){
   //const [isRecording, setIsRecording] = useState(false);
   const [buttonDebounceTimeout, setButtonDebounceTimeout] = useState(null);
   const [recordingPercent, setRecordingPercent] = useState(0);
   const [progressRingWidth, setProgressRingWidth] = useState(92);


   let animationInterval = null;

   useEffect(() => {
      try {
         let buttonElement = document.getElementById('videoPlayingUIStopButton');
         let style = getComputedStyle(buttonElement);
         setProgressRingWidth(parseInt(style.width));
      }
      catch (e){
         console.log(e);
      }
      return (() => {
      });
   }, []);

   let onButtonDown = (e) => {
      e.preventDefault();
      let timeout = setTimeout(() => {
         let animInterval = window.setInterval(() => {
            let curPercentage = window.BV.photoBoothExperience.recordingPercentage;
            if(curPercentage >= 100){
               console.log(curPercentage);
               onButtonUp(true);
            }
            setRecordingPercent(curPercentage);
         }, 50);
         animationInterval = animInterval;
         props.startRecording();
         let buttonElement = document.getElementById('videoPlayingUIStopButton');
         buttonElement.classList.add('pressed');
      }, 500);
      setButtonDebounceTimeout(timeout);
   };

   let onButtonUp = () => {
      let isRecording = window.BV.photoBoothExperience.isRecording;
      if(!isRecording){
         if(buttonDebounceTimeout){
            clearTimeout(buttonDebounceTimeout);
         }
         props.takePicture();
      }
      else {
         props.stopRecording();
         window.clearInterval(animationInterval);
         let buttonElement = document.getElementById('videoPlayingUIStopButton');
         buttonElement.classList.remove('pressed');
      }
   };

   return (
      <div className={'multi-button'}>
         <div id={'videoPlayingUIStopButton'}
            onMouseDown={(e) => onButtonDown(e)}
            onMouseUp={() => onButtonUp()}
            onTouchStart={(e) => onButtonDown(e)}
            onTouchEnd={() => onButtonUp()}
         >
            <ReactProgressRing width={progressRingWidth} percentage={recordingPercent}  />
         </div>

      </div>
   );
}

export function TakeButton(props) {
   if(props.manualCaptureScreenTakeButtonUrl) {
      return (
         <button
            className={'take-button'}
            onClick={() => props.onClick()}
         >
            <img className={'take-button-image'} src={resolveUrl(props.manualCaptureScreenTakeButtonUrl)}/>
         </button>
      );
   } else if(props.manualCaptureScreenTakeText){
      return (
         <button className={'take-button'} onClick={() => props.onClick()}>
            {props.manualCaptureScreenTakeText}
         </button>
      );
   }
   else {
      return null;
   }
}

export function RetakeButton(props){
   if(props.manualCaptureScreenRetakeButtonUrl){
      return (
         <button
            className={'retake-button'}
            onClick={() => props.onClick()}
         >
            <img className={'back-button-image'} src={resolveUrl(props.manualCaptureScreenRetakeButtonUrl)} />
         </button>
      );
   }
   else if(props.manualCaptureScreenRetakeText){
      return (
         <button className={'retake-button'} onClick={() => props.onClick()}>
            {props.manualCaptureScreenRetakeText}
         </button>
      );
   }
   else {
      return null;
   }
}